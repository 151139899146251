import {Helmet} from "react-helmet-async";
import SaasHeader from "../../../../Components/SaasHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SaasBox from "../../../../Components/SaasBox";
import {Link} from "react-router-dom";
import DetailsForm from "../../DetailsForm";
import {useGetPaymentDetailsMutation} from "../../../../app/api/userApi";
import {useEffect, useState} from "react";
import {useModal} from "../../../../Components/Modal/modal-context";
import useRoleRedirect from "../../../../hooks/useRoleRedirect";
import Confirmation from "../../../../Components/Modal/ConfirmationModal";
import * as yup from "yup";
import AddCardComponent from "./AddCard";
import Loading from "../../../../Components/Loading";


const Payments = () =>  {

    useRoleRedirect('Licensee')

    const { setModal, unSetModal } = useModal()

    const [details, setDetails] = useState({});

    const [getPaymentDetails, {isLoading, isError}] = useGetPaymentDetailsMutation()


    const getDetails = async () => {
        if (!isLoading) {
            const response =  await getPaymentDetails({type:"payment"})
            console.log(response)
            if (response?.data) {
                setDetails(response?.data)
            }

            unSetModal()

        }
    }

    useEffect(() => {
        getDetails()
    }, []);

    const deletePayment = async (id) => {

    }

    return (
        <>
            <Helmet>
                <title>Cohesii | Settings | Payments</title>
            </Helmet>
            <SaasHeader title={"Payments"} add={false} >
                <div className={'back-link'} slot={"back"}>
                    <Link to={'/account/overview'}>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className={'mr-2'} />
                        Back to settings
                    </Link>
                </div>
            </SaasHeader>

            <div className={'bg-yellow-50 border-yellow-300 border-[1px] p-3 text-gray-800 mb-5 mt-5 text-sm'}>
                <FontAwesomeIcon icon="fa-solid fa-circle-info" className={'mr-2 text-yellow-500'} /> The payment is powered by Stripe and <strong>we do not store sensitive payment information on our servers</strong>.
            </div>

            <Loading isLoading={isLoading} />
            {!isLoading && (
                <SaasBox className={'mt-4'}>
                    <h3 className={"section__title"}>Personal details</h3>
                    <p className={"section__strap"}>Enter your personal details relating to your payment methods.</p>
                        <DetailsForm type={"payment"} defaults={details} additional_schema={{
                            vat_number: yup
                                .string()
                                .typeError('Vat Number must be a string'),
                        }} id={details?.id} />
                </SaasBox>
            )}

            {(details?.stripe_customer_id && !isLoading) && (
                <SaasBox className={'mt-4'}>
                    <div className={
                        'flex flex-row'
                    }>
                        <h3 className={"section__title flex-1"}>Attached Cards</h3>

                        <button type={"button"}
                                className={'btn__auth_primary small !mt-0'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setModal(<AddCardComponent
                                        details={details}
                                        stipeCustomerId={details?.stripe_customer_id} callback={getDetails} />)
                                }}>
                            Link new card
                        </button>
                    </div>
                    <div className={'accounts mt-4'}>
                        {details?.cards?.map((card, index) => (
                            <div key={card.fingerprint}
                                 className={'flex flex-row gap-4 py-4 border-b-[1px] first-of-type:border-t-[1px] border-gray-100'}>
                                <span className={'bg-gray-100 rounded-lg p-2 w-10 items-center justify-center flex'}>
                                    <FontAwesomeIcon icon={`fa-brands fa-cc-${card.display_brand}`} className={'text-gray-800'}/>
                                </span>
                                <div className={'flex flex-col  flex-auto'}>
                                    <p className={'font-montserrat font-bold text-gray-800 dark:text-gray-200'}>
                                        {card.display_brand}
                                    </p>
                                    <p className={'text-sm ext-gray-800 dark:text-gray-200 flex flex-row gap-2'}>
                                        <span>{card.exp_month + '/'+ card.exp_year}</span> <span>•••• {card.last4}</span>
                                    </p>
                                </div>

                                <button type={"button"}
                                        className={'bg-red-500 hover:bg-red-400 text-white p-4 rounded-lg flex items-center justify-center flex-0 h-4 w-4'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModal(<Confirmation itemType={'Bank card'} deleteAction={() => { deletePayment(card.local_id) }} />  )
                                        }}>
                                    <FontAwesomeIcon icon="fa-solid fa-trash"/>
                                </button>


                            </div>
                        ))}
                    </div>
                </SaasBox>
            )}
        </>
    )

}


export default Payments