import {useEffect, useState} from "react";

const CalculatorResults = ({price = 0 , data, isLoading = false}) => {

    const [subtotal, setSubtotal] = useState(0.00)
    const [margin, setMargin] = useState(0.00)
    const [cost, setCost] = useState(0.00)


    useEffect(() => {
        setSubtotal(price * data.min_hours)
        setMargin(price > 0 ? ((data?.margin ?? 0) / price * 100) : 0)
        setCost(price > 0 ? (parseFloat(process.env.REACT_APP_COMMISSION) / price * 100) : 0)
    }, [price, data])

    return (
        <div className={'calculator-results'}>
            <p className={'text-gray-500 font-bold'}>
                Below is the estimated recommended price.
            </p>
            {!isLoading && (
                <>
                    <p className={'text-2xl font-bold flex flex-row mt-4 mb-4'}>
                        <span className={'flex-1 text-gray-900'}>Price:</span>
                        <span className={'text-blaze-500'}>£{price.toFixed(2) || 0.00}</span>
                    </p>

                    <p className={'text-2xl font-bold flex flex-row mt-4 mb-4 pb-6 border-b-2 border-gray-100'}>
                        <span className={'flex-1 text-gray-900'}>Price per person:</span>
                        <span className={'text-blaze-500'}>£{(price / data.people).toFixed(2)}</span>
                    </p>

                    <div className={'calculator-results__breakdown'}>
                        <p className={'text-gray-500 text-sm'}>
                            Below is the breakdown of your estimated recommended price.
                        </p>
                        <ul className={'flex flex-col mt-5 gap-2'}>
                            <li className={'flex flex-row'}>
                                <span className={'flex-1 text-gray-500'}>Subtotal</span>
                                <span
                                    className={'text-blaze-500'}>£{subtotal.toFixed(2)}</span>
                            </li>
                            <li className={'flex flex-row'}>
                                <span className={'flex-1 text-gray-500'}>Margin</span>
                                <span className={'text-blaze-500'}>£{margin.toFixed(2)}</span>
                            </li>
                            <li className={'flex flex-row'}>
                                <span className={'flex-1 text-gray-500'}>Cost</span>
                                <span className={'text-blaze-500'}>- £{cost.toFixed(2)}</span>
                            </li>
                            <li className={'flex flex-row'}>
                                <span className={'flex-1 text-gray-500'}>Total</span>
                                <span
                                    className={'text-blaze-500'}>£{((subtotal + margin) - cost).toFixed(2)}</span>
                            </li>
                        </ul>
                    </div>
                </>
            )}
        </div>
    )

}

export default CalculatorResults