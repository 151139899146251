import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import {getDaysInCurrentMonth, getPrevious12Months, getWeeksFromLastMonthToCurrent} from "./Utilities";



const monthRange =  getPrevious12Months()
const weekRange =  getWeeksFromLastMonthToCurrent()
const dayRange =  getDaysInCurrentMonth()


const demoTestData = {
    'month': [
        {
            name: 'Visits',
            data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
        },

        {
            name: 'Bookings',
            data:  [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 45],
        },
    ],
    'week': [
        {
            name: 'Visits',
            data: [100, 39, 42, 66],
        },

        {
            name: 'Bookings',
            data: [30, 36, 39, 51],
        },
    ],
    'day': [
        {
            name: 'Visits',
            data: [36, 52, 40, 80,  64, 52,90],
        },

        {
            name: 'Bookings',
            data: [30, 36, 39, 51, 22, 33, 45],
        },
    ],
}

export const ChartOne = () => {

    const [type, setType] = useState('month')
    const [state, setState] = useState({
        series: [
            {
                name: 'Visits',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 45],
            },

            {
                name: 'Bookings',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 51],
            },
        ],
    });

    const [axis, setAxis] = useState(monthRange)


    const setCatRange = (type) => {
        setType(type)
        switch (type) {
            case 'day':
                setAxis(dayRange)
                setState({'series':demoTestData.day})
                break;
            case 'month':
                setAxis(monthRange)
                setState({'series':demoTestData.month})
                break;
            case 'week':
                setAxis(weekRange)
                setState({'series':demoTestData.week})
                break;
        }
    }

    const options = {
        legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'left',
        },
        colors: ['#f26716', '#411309'],
        chart: {
            fontFamily: 'Satoshi, sans-serif',
            height: 500,
            type: 'area',
            toolbar: {
                show: false,
            },
        },
        responsive: [
            {
                breakpoint: 1024,
                options: {
                    chart: {
                        height: 500,
                    },
                },
            },
            {
                breakpoint: 1366,
                options: {
                    chart: {
                        height: 500,
                    },
                },
            },
        ],
        stroke: {
            width: [2, 2],
            curve: 'straight',
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 4,
            colors: '#fff',
            strokeColors: ['#f26716', '#411309'],
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            hover: {
                size: undefined,
                sizeOffset: 5,
            },
        },
        xaxis: {
            type: 'category',
            categories: axis,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            title: {
                style: {
                    fontSize: '0px',
                },
            },
            min: 0,
            max: 100,
        },
    };


    return (
        <div className="saas-box col-span-12 border-stroke bg-white px-5 pt-7 pb-5 sm:px-7 xl:col-span-7">
            <div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
                <div className="flex w-full flex-wrap gap-3 sm:gap-5">
                    <div className="flex min-w-[11rem]">
                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-blaze-500">
                          <span className="block h-2 w-full max-w-2 rounded-full bg-blaze-500"></span>
                        </span>
                        <div className="w-full">
                            <p className="font-semibold text-gray-800 dark:text-gray-200">Total Bookings</p>
                            <p className="text-sm font-medium text-nowrap">12.04.2022 - 12.05.2022</p>
                        </div>
                    </div>
                    <div className="flex  min-w-[11rem]">
                        <span className="mt-1 mr-2 flex h-4 w-full max-w-4 items-center justify-center rounded-full border border-blaze-950">
                          <span className="block h-2 w-full max-w-2 rounded-full bg-blaze-950"></span>
                        </span>
                        <div className="w-full">
                            <p className="font-semibold text-gray-800  dark:text-gray-200">Total Visits</p>
                            <p className="text-sm font-medium text-nowrap">12.04.2022 - 12.05.2022</p>
                        </div>
                    </div>
                </div>
                <div className="flex w-full max-w-45 justify-end">
                    <div className="cat-range">
                        <button className={`cat-range-selector ${(type === 'day') ? 'active' :''}`} onClick={(e) => {setCatRange("day")}}>
                            Day
                        </button>
                        <button className={`cat-range-selector ${(type === 'week') ? 'active' :''}`}  onClick={(e) => {setCatRange("week")}}>
                            Week
                        </button>
                        <button className={`cat-range-selector ${(type === 'month') ? 'active' :''}`} onClick={(e) => {setCatRange("month")}}>
                            Month
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <div id="chartOne" className="-ml-5">
                    <ReactApexChart
                        options={options}
                        series={state.series}
                        type="area"
                        height={450}
                    />
                </div>
            </div>
        </div>
    );
};

