import Form from "../Forms/Form";
import {StepFooter} from "./NewSpace";
import {Input, InputFieldGroup, Textarea} from "../Forms/InputField";

import * as yup from "yup"
import { useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUpdateSessionMutation} from "../../app/api/spacesApi";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const aboutSchema = yup
    .object({
        name: yup.string().required("Space name is required"),
        description: yup.string().min(300, "Description should be at least 300 characters").required("Space description is required"),
        size: yup.number().min(0, "Size can not be negative").required("Space Size is required").typeError('A number is required'),
        capacity: yup.number().min(0, "Capacity can not be negative").required("Capacity Is Required").typeError('A number is required'),
        wifi_name: yup.string().optional(),
        wifi_password: yup.string().optional(),
        access: yup.string().min(300, "Instructions should be at least 300 characters").required("Arrival instructions are required"),
    })
    .required()

const AboutComponent = ({setActiveStep, markStepAsCompleted, session, defaultData}) => {

    const [updateSession, {isLoading}] = useUpdateSessionMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
        resolver: yupResolver(aboutSchema),
    })




    const submit = async (data) => {

        if (!isLoading) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 2,
                    "data": {"about": data}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success("Space About updated successfully")
                markStepAsCompleted(2)
                setActiveStep(null)
            }

        }
    }

    return (
        <>
            <div className={'new-space__location'}>
                <Form onSubmit={handleSubmit(submit)} id="locationForm" className="space__location_form">
                    <fieldset className="input__fieldset new-space__subsection">
                        <div className={"new-space__subheader"}>
                            <h2 className={'new-space__subsection_title'}>Give your space a name</h2>
                            <p className={'new-space__subsection_strap'}>Create a name that will explain your space</p>
                        </div>
                        <div className={'new-space__subsection_content'}>
                            <p>Try to include the following:</p>
                            <ul>
                                <li>Building name or location</li>
                                <li>The type of space - office, studio, venue</li>
                                <li>Explanatory words - cosy, roomy, collaborative.</li>
                            </ul>
                        </div>

                        <Input
                            showLabel={false}
                            register={register}
                            label={"Enter your space name"}
                            id={"name"}
                            required={true}
                            errors={errors}
                        />
                    </fieldset>

                    <fieldset className="input__fieldset new-space__subsection">
                        <div className={"new-space__subheader"}>
                            <h2 className={'new-space__subsection_title'}>Describe your space</h2>
                            <p className={'new-space__subsection_strap'}>Include as much information about your space as
                                you can. </p>
                        </div>
                        <div className={'new-space__subsection_content'}>
                            <p>Try to answer common questions like:</p>
                            <ul>
                                <li>What industries work well in your office space?</li>
                                <li>What sort of layout do you have?</li>
                                <li>What makes your space unique?</li>
                                <li>What sort of activities can be conducted in your space?.</li>
                            </ul>

                            <p>Do not include:</p>
                            <ul>
                                <li>Payment information - This includes any paypal emails or bank transfer details</li>
                                <li>Direct contact information - This includes, addresses, emails, links or phone
                                    numbers
                                </li>
                            </ul>
                        </div>

                        <Textarea
                            showLabel={false}
                            register={register}
                            label={"Describe your space"}
                            id={"description"}
                            required={true}
                            errors={errors}
                        />
                    </fieldset>

                    <fieldset className="input__fieldset new-space__subsection">
                        <div className={"new-space__subheader"}>
                            <h2 className={'new-space__subsection_title'}>How big is your space ?</h2>
                            <p className={'new-space__subsection_strap'}>Please include the size and capacity of the
                                bookable space.</p>
                        </div>
                        <InputFieldGroup>
                            <Input
                                showLabel={false}
                                register={register}
                                type={"number"}
                                label={"500"}
                                id={"size"}
                                required={true}
                                errors={errors}
                                suffix={'sq ft'}
                            />
                            <Input
                                showLabel={false}
                                register={register}
                                type={"number"}
                                label={"500"}
                                id={"capacity"}
                                required={true}
                                errors={errors}
                                suffix={'people'}
                            />
                        </InputFieldGroup>
                    </fieldset>

                    <fieldset className="input__fieldset new-space__subsection">
                        <div className={"new-space__subheader"}>
                            <h2 className={'new-space__subsection_title'}>What’s your wifi name and password?</h2>
                            <p className={'new-space__subsection_strap'}>
                                Improve your guest experience by sharing your WI-FI information ahead of time.
                            </p>
                        </div>
                        <InputFieldGroup>
                            <Input
                                showLabel={false}
                                register={register}
                                label={"Optional - Wifi Name"}
                                id={"wifi_name"}
                                required={true}
                                errors={errors}
                            />
                            <Input
                                showLabel={false}
                                register={register}
                                type={"password"}
                                label={"Optional - Wifi Password"}
                                id={"wifi_password"}
                                required={true}
                                errors={errors}

                            />
                        </InputFieldGroup>
                        <p className={'mt-2 font-sm text-gray-500'}>
                            <FontAwesomeIcon icon="fa-solid fa-lock"/> Don't worry, we'll only share this with guests in
                            their confirmation booking email.
                        </p>
                    </fieldset>

                    <fieldset className="input__fieldset new-space__subsection">
                        <div className={"new-space__subheader"}>
                            <h2 className={'new-space__subsection_title'}>Provide arrival instructions</h2>
                            <p className={'new-space__subsection_strap'}>
                                Help your guests and attendees find your space and access rooms or areas.
                            </p>
                        </div>
                        <div className={'new-space__subsection_content'}>
                            <p>Try to answer common questions like:</p>
                            <ul>
                                <li>Typical complex directions - where do people normally get lost?</li>
                                <li>Building access codes or requirements - Buzzers to press?</li>
                                <li>What floor do they need to take stairs or elevators.</li>
                                <li>Do you have extra requirements for accessibility?.</li>
                                <li>Car parking information.</li>
                            </ul>
                        </div>

                        <Textarea
                            showLabel={false}
                            register={register}
                            label={"How do guests get access to your space"}
                            id={"access"}
                            required={true}
                            errors={errors}
                        />
                        <p className={'mt-2 font-sm text-gray-500'}>
                            <FontAwesomeIcon icon="fa-solid fa-lock"/> Don't worry, we'll only share this with guests in
                            their confirmation booking email.
                        </p>
                    </fieldset>

                    <StepFooter setActiveStep={setActiveStep}/>
                </Form>
            </div>

        </>
    )
}

export default AboutComponent;