import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {useModal} from "./modal-context";
import Loading from "../Loading"; // Ensure you have this package installed

const Confirmation = ({
                                itemType, // e.g., 'space', 'user', 'post'
                                deleteAction,
                                completeCallback = () => {},
                                errorCallback = () => {},
                                customMessage = `Are you sure you want to delete this ${itemType}?`
                            }) => {
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const { unSetModal } = useModal();

    const [isLoading, setIsLoading] = useState(false);

    const doDelete = async () => {
        try {
            setIsLoading(true)
            const response = await deleteAction();

            if (response === false) {
                toast.error(`Error deleting ${itemType.charAt(0).toUpperCase() + itemType.slice(1)}`);
                errorCallback();
                setIsLoading(false)
                return false;
            }

            if (response) {
                setIsLoading(false)
                toast.success(`${itemType.charAt(0).toUpperCase() + itemType.slice(1)} deleted successfully`);
            }

            unSetModal();
        }finally {
            setIsLoading(false)
            setDeleteDisabled(true)
        }

    };

    return (
        <div className={'delete__confirmation flex flex-col'}>
            <h2 className={'section__title'}>Delete {itemType.charAt(0).toUpperCase() + itemType.slice(1)}</h2>
            <p className={'section__strap'}>{customMessage}</p>

            <label className={'flex gap-3 text-gray-500 dark:text-gray-100 text-sm'} htmlFor={'confirmDelete'}>
                <input
                    type={'checkbox'}
                    name={'confirmDelete'}
                    id={'confirmDelete'}
                    onChange={(e) => setDeleteDisabled(!e.target.checked)}
                />
                <span>Selecting this checkbox confirms you understand that deleting this entry will remove all related content.</span>
            </label>

            <div className={'flex flex-row gap-8 flex-row-reverse'}>
                <button
                    onClick={doDelete}
                    disabled={deleteDisabled || isLoading}
                    type={'button'}
                    className={'btn__auth_primary w-auto !flex-0'}
                >
                    {!isLoading ? 'Delete' : 'Processing...'}
                </button>

                <button
                    onClick={unSetModal}
                    type={'button'}
                    className={'btn__auth_back w-auto !flex-0'}
                >
                    Cancel
                </button>
            </div>

        </div>
    );
};

export default Confirmation;