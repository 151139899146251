import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input, InputFieldGroup} from "../../Components/Forms/InputField";
import {Form} from "react-router-dom";
import countryList from "react-select-country-list";
import {useEffect, useMemo} from "react";
import Select from "react-select";
import {useGetMeMutation, useSavePaymentDetailsMutation} from "../../app/api/userApi";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../app/features/auth/authSlice";
import DateField from "../../Components/Forms/Date";
import PhoneInputWrapper, {isPhoneValid} from "../../Components/Forms/PhoneInput";

const detailsSchema = {
    id: yup
        .string()
        .nullable(),

    company: yup
        .string()
        .required('Company name is required'),

    name: yup
        .string()
        .required('Name is required'),

    line1: yup
        .string()
        .required('Address line 1 is required'),

    line2: yup
        .string()
        .nullable(), // Optional field, can be null

    city: yup
        .string()
        .required('City is required'),

    state: yup
        .string()
        .nullable(), // Optional field, can be null

    postal_code: yup
        .string()
        .required('Postal code is required'),

    country: yup
        .string()
        .required('Country is required'),

    phone: yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone number is not invalid', (value) => isPhoneValid(value, 'gb'))
}

// Default values for fields

// A function to merge the defaults with the provided values


const DetailsForm = ({type, defaults = null, additional_schema = {}, id = null}) => {

    const ip = useSelector((state) => state.auth.ipAddress);

    const dispatch = useDispatch();
    const [getMe] = useGetMeMutation();

    const defaultValues = {
        company: '',
        name: '',
        line1: '',
        line2: null,  // Nullable field
        city: '',
        state: null,  // Nullable field
        postal_code: '',
        dob_day: null,
        dob_month: null,
        dob_year: null,
        country: 'GB',  // Default to 'GB'
        ...defaults
    };


    const [savePaymentDetails, {isLoading, isError}] = useSavePaymentDetailsMutation()

    const options = useMemo(() => countryList().getData(), [])

    const detailsFullSchema = yup
        .object({
            ...detailsSchema,
            ...additional_schema
        })
        .required()

    const {
        register,
        handleSubmit,
        control,
        formState: { errors,isDirty, isValid },
        setValue, trigger
    } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(detailsFullSchema),
        mode: 'onBlur'
    })

    const submit = async (data) => {

        data.ip_address = ip
        data.details_type=type

        if (!isLoading) {

            const response = await savePaymentDetails({data: data, id: id})
            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success(`${type} details updated successfully`)

                const { data, error } = await getMe({});
                if (error) {
                    throw new Error("Failed to fetch user");
                }
                // Dispatch user data only once after fetching
                dispatch(setUser(data));
            }
        }
    }

    useEffect(() => {
        console.log(errors, isValid)
    }, [errors, isValid]);

    return (
        <>
            <Form onSubmit={handleSubmit(submit)} id="paymentDetailsForm" className="settings__payment_details_form">
                <fieldset className="input__fieldset">
                    <InputFieldGroup>
                        <Input
                            register={register}
                            label={"Company"}
                            id={"company"}
                            type={"text"}
                            required={true}
                            errors={errors}
                        />


                        {type === 'payment' && (
                            <Input
                                register={register}
                                label={"Vat Number"}
                                id={"vat_number"}
                                type={"text"}
                                required={true}
                                errors={errors}
                            />
                        )}

                        <Input
                            register={register}
                            label={"Name"}
                            id={"name"}
                            type={"text"}
                            required={true}
                            errors={errors}
                        />
                        {type === 'payout' && (
                            <DateField control={control} errors={errors} label={"Date of Birth"} />
                        )}
                    </InputFieldGroup>


                    <InputFieldGroup>
                        <Input
                            register={register}
                            label={"Address"}
                            id={"line1"}
                            type={"text"}
                            required={true}
                            errors={errors}
                        />


                        <Input
                            register={register}
                            label={"Apartment, suite or building number."}
                            id={"line2"}
                            type={"text"}
                            required={false}
                            errors={errors}
                        />
                    </InputFieldGroup>

                    <InputFieldGroup>
                        <div className="input__field">
                            <label className="input__field_label normal" htmlFor="country">Country *</label>
                            <Controller
                                control={control}
                                name={"country"}
                                render={({field: {onChange, onBlur, value, ref}}) => (
                                    <Select options={options}
                                            ref={ref}
                                            value={options.find(c => c.value === value)}
                                            onChange={val => onChange(val.value)}
                                            classNames={{
                                                control: () => `${errors.country ? "error" : ""} react-select`,
                                                option: () => "react-select__option",
                                            }}
                                    />
                                )}
                            />
                            {errors.country && (
                                <p className="text-red-500 text-sm mt-1">{errors.country.message}</p>
                            )}

                        </div>
                        <Input
                            register={register}
                            label={"City"}
                            id={"city"}
                            type={"text"}
                            required={true}
                            errors={errors}
                        />
                        <Input
                            register={register}
                            label={"State/County"}
                            id={"state"}
                            type={"text"}
                            required={false}
                            errors={errors}
                        />
                        <Input
                            register={register}
                            label={"Postcode/Zip code"}
                            id={"postal_code"}
                            type={"text"}
                            required={true}
                            errors={errors}
                        />
                    </InputFieldGroup>

                    <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                            <PhoneInputWrapper
                                {...field}
                                label="Phone Number"
                                required
                                errors={errors}
                                validateFieldChange={(name, value) => {
                                    setValue(name, value);
                                    trigger(name); // Trigger validation for the field
                                }}
                                handleInputChange={(e) => {
                                    setValue(e.target.name, e.target.value);
                                    trigger(e.target.name); // Trigger validation on change
                                }}
                            />
                        )}
                    />





                    <p className={'text-gray-500 dark:text-gray-200 mt-4 text-sm'}>By confirming your payout details, you agree to our Services Agreement and the
                        <a className="text-blaze-500 hover:text-blaze-600 dark:text-blaze-100 dark:hover:text-blaze-200" target={"_blank"}
                           href="https://stripe.com/connect-account/legal/full">
                            <span> Stripe Connected Account Agreement</span>
                        </a>.
                    </p>

                    {isDirty &&
                        (
                            <div className={'new-space__footer flex-1 !justify-end'}>

                                <button type={"submit"} disabled={isLoading} className={'btn__auth_primary small'}>
                                    {isLoading ? 'Processing...' : 'Create payout account' }
                                </button>
                            </div>
                        )
                    }

                </fieldset>
            </Form>

        </>
    )
}

export default DetailsForm