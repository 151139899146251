
import {Helmet} from "react-helmet-async";
import {ChartOne} from "../../Components/Charts";
import {FeedbackWidget} from "../../Components/Widgets";
import {useSelector} from "react-redux";



const OwnerDashboard = () => {
    const user = useSelector((state) => state.auth.user);

    return (
        <>
            <Helmet>
                <title>Cohesii |Dashboard</title>
            </Helmet>

            <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
                <div className={'col-span-12'}>
                    <h3 className={'font-bold font-montserrat text-3xl text-gray-800 dark:text-gray-300'}> Welcome back <span className={'text-blaze-500'}> {user?.name}</span></h3>
                    <p className={'text-sm text-gray-500 dark:text-gray-200 mt-2'}>Check out your latest account analytics and whats going on with your spaces.</p>
                </div>
                <ChartOne/>
                <FeedbackWidget />
            </div>
        </>

    )

}

export default OwnerDashboard