import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";

export const OwnerRoleNavLink = ({  children, ...props}) => {

    const userRoles = useSelector((state) => state.auth.user?.roles)

    return (
        <>
            {
                userRoles?.includes("Space Owner") &&
                <li><NavLink {...props}>{children}</NavLink></li>
            }
        </>
    )
}

export const LicenseeRoleNavLink = ({  children, ...props}) => {

    const userRoles = useSelector((state) => state.auth.user?.roles)

    return (
        <>
            {
                userRoles?.includes("Licensee") &&
                <li><NavLink {...props}>{children}</NavLink></li>
            }
        </>
    )
}