import {useGetSsoUrlsByActionQuery} from "../../app/api/authApi";
import {Link} from "react-router-dom";
import React from "react";

const SocialConnect = ({action = 'login'}) => {

    const { data = [], error,isFetching,  isLoading } = useGetSsoUrlsByActionQuery(action)

    return (
        <>
            {!isLoading && !isFetching && !error && (
                <div className={'socials'}>
                    {
                        data.map((social) => (
                            <Link to={social.url} key={social.type} className="socials__link">
                               <i className={`fab fa-brands fa-${social.type === 'linkedin-openid' ? 'linkedin' : social.type}`} />
                                {action === 'login' ? 'Sign in with ': 'Register with '}
                                {social.type === 'linkedin-openid' ? 'linkedin' : social.type}
                            </Link>
                        ))
                    }
                </div>
            )}
        </>
    )
}

export default SocialConnect;