import React, { useState, useEffect, useRef } from 'react';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';

const SUBSCRIPTION_KEY = '37e8c906e2c24081953a8abdc1679158';
const ENDPOINT = 'https://cohesii.cognitiveservices.azure.com/stt/speech/recognition/conversation/cognitiveservices/v1';
const REGION = 'uksouth';

export default function Dictaphone({setQuery}) {

    const [isListening, setIsListening] = useState(false);
    const speechConfig = useRef(null);
    const audioConfig = useRef(null);
    const recognizer = useRef(null);


    let full_text = useRef("");


    useEffect(() => {
        speechConfig.current = sdk.SpeechConfig.fromEndpoint(
            new URL(ENDPOINT),
            SUBSCRIPTION_KEY
        );

        audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();

        //speechConfig.current.speechRecognitionLanguage = "en-GB";

        recognizer.current = new sdk.SpeechRecognizer(speechConfig.current, audioConfig.current);

        console.log(recognizer.current)

        recognizer.current.recognized = (s, e) => {
            console.log('recognizer recognized', s, e);
            if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
                full_text.current += " " +e.result.text
                setQuery(full_text.current)
            }
            else if (e.result.reason === sdk.ResultReason.NoMatch) {
                console.log("NOMATCH: Speech could not be recognized.");
            }
        };

        recognizer.current.recognizing =  (s, e) => {
            console.log(`RECOGNIZING: Text=${e.result.text}`);
        };

        recognizer.current.canceled = (s, e) => {
            console.log(`CANCELED: Reason=${e.reason}`);

            if (e.reason === sdk.CancellationReason.Error) {
                console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                console.log("CANCELED: Did you set the speech resource key and region values?");
            }

            recognizer.current.stopContinuousRecognitionAsync();
            setIsListening(false);
            full_text.current = ""
        };

        recognizer.current.sessionStopped = (s, e) => {
            recognizer.current.stopContinuousRecognitionAsync();
            setIsListening(false);
            full_text.current = ""
        };


        return () => {
            recognizer.current.stopContinuousRecognitionAsync(() => {
                console.log('Speech recognition stopped.');
                setIsListening(false);
                full_text.current = ""
            });
        };
    }, []);


    const run = () => {
        if (!isListening) {
            setIsListening(true);
            recognizer.current.startContinuousRecognitionAsync(() => {
                console.log('Resumed listening...');
                setQuery("")
            });
        }else {
            setIsListening(false);
            recognizer.current.stopContinuousRecognitionAsync(() => {
                console.log('Speech recognition stopped.');
            });
        }
        full_text.current = ""
    };

    return (
        <div>
            <button className={!isListening ? "pulse-trans": "pulse"} onClick={run}>
                <svg strokeWidth="0" viewBox="0 0 16 16"
                     className={"w-[30px] h-[30px]"}
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"></path>
                    <path
                        d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"></path>
                </svg>
            </button>
        </div>
    );
};


