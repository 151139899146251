import {useDispatch, useSelector} from "react-redux";
import {usePutMeMutation} from "../../../../app/api/userApi";
import SaasBox from "../../../../Components/SaasBox";
import React, {useEffect, useState} from "react";

import ImageDragDrop from "../../../../Components/Forms/Image";
import {toast} from "react-toastify";
import {setUser} from "../../../../app/features/auth/authSlice";

const isGravatarUrl = (url) => {
    // Regex to match Gravatar URLs
    const gravatarRegex = /^https?:\/\/(www\.)?gravatar\.com\/avatar\/[a-f0-9]{32}(?:\?.*)?$/i;
    return gravatarRegex.test(url);
};

const PhotoForm = () => {

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch()

    const [putMe, {isLoading}] = usePutMeMutation()

    const [image, setImage] = useState(user?.photo);

    const isGrav = isGravatarUrl(user?.photo)

    const upload = async (payload) => {
        if (!isLoading)
        {
            const response = await putMe({data:payload})
            if (response.error) {
                toast.error("We could not upload image")
                return false;
            }

            if (response.data) {
                toast.success(`Image updated successfully`)

                // Dispatch user data only once after fetching
                dispatch(setUser(response.data));
            }
        }
    }


    useEffect(() => {
        if (image !== user?.photo) {
            const photo = {
                photo:image
            }
            const payload = {
                ...user,
                ...photo
            }

            console.log(payload)

            upload(payload)
        }
    }, [image])
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    // Handle file drop
    const handleDrop = async (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0]; // Get the first file from the dropped files
        if (file && file.type.startsWith('image/')) {
            const base64 = await convertToBase64(file); // Convert image to base64
            setImage(base64); // Update the image state with the base64 string
        }
    };

    // Handle file browse
    const handleBrowse = async (e) => {
        e.preventDefault();
        const file = e.target.files[0]; // Get the first file from file input
        if (file && file.type.startsWith('image/')) {
            const base64 = await convertToBase64(file); // Convert image to base64
            setImage(base64); // Update the image state with the base64 string
        }
    };

    return (
        <div className="col-span-5 xl:col-span-2">
            <SaasBox>
                <h3 className="section__title">Your Photo</h3>
                <p className={"section__strap"}>Delete or update your image. Remember to save any changes</p>
                <div className="mb-4 flex items-center gap-3">
                    <div className="h-14 w-14 rounded-full overflow-hidden"><img src={image} alt="User"/></div>
                   <div>
                        <span className="mb-1.5 text-black dark:text-white">Edit your photo</span>
                       {!isGrav &&  <span
                        className="flex gap-2.5">
                            <button onClick={(e) => {setImage("")}} className="text-sm hover:text-primary">Delete</button>
                        </span>}
                    </div>

                </div>
                <ImageDragDrop handleDrop={handleDrop} handleBrowse={handleBrowse} />
            </SaasBox>
        </div>
    )
}

export default PhotoForm