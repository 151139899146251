import React, { forwardRef } from 'react';
import { PhoneInput, defaultCountries, parseCountry } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css';
import { InputField } from './InputField';

const phoneUtil = PhoneNumberUtil.getInstance();

// Function to validate phone number (for Yup custom validation)
export const isPhoneValid = (phone, countryCode) => {
    try {
        const parsedNumber = phoneUtil.parseAndKeepRawInput(phone, countryCode);
        return phoneUtil.isValidNumber(parsedNumber);
    } catch (error) {
        return false;
    }
};

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['us', 'gb'].includes(iso2); // Filter for specific countries
});



const PhoneInputWrapper = forwardRef(({
                                          label = 'Phone Number',
                                          required = false,
                                          name = 'phone',
                                          ariaLabel = 'phone',
                                          value,
                                          handleInputChange,
                                          validateFieldChange,
                                          errorMessage = `${label} is required and must be valid`,
                                          countryCode = 'gb',
                                          errors, // Pass the errors object
                                          ...rest // Catch other props if needed
                                      }, ref) => {

    const phoneError = errors?.[name]; // Retrieve error for the phone input

    return (
        <InputField>
            <label className="input__field_label normal" htmlFor={name}>
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <PhoneInput
                name={name}
                defaultCountry={countryCode}
                required={required}
                country={countryCode}
                id={name}
                aria-label={ariaLabel}
                value={value}
                countries={countries}
                ref={ref} // Forward the ref here
                onBlur={(e) => validateFieldChange(name, e.target.value)} // On blur, trigger validation
                onChange={(phone) => handleInputChange({ target: { name, value: phone } })} // Handle input change
                {...rest} // Pass any additional props
            />
            {phoneError && (
                <p className="text-red-500 dark:text-red-200 text-sm mt-1 mb-2">
                    {phoneError.message || errorMessage}
                </p>
            )}
        </InputField>
    );
});

export default PhoneInputWrapper;