import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const DemoMode = () => {

    const isDemo = process.env.REACT_APP_MODE

    return (
        <>
            {isDemo && (
                <div className="demo-mode">
                    <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className={'mr-4'}/>
                    This is a demo environment and such uses data intended for demo purposes only.
                </div>
            )}
        </>
    )

}
