import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

const SaasHeader = ({title, view, setView, add = false, children }) => {

    let children_blocks = null;
    let back = null;

    React.Children.forEach(children, (child) => {
        if (child.props.slot === 'back') {
            back = child;
        } else {
            children_blocks = child
        }

    });

    return (
        <div className="mb-6 flex gap-3 flex-row sm:items-center sm:justify-between">
            <div className={'flex flex-row-reverse flex-1 items-center'}>
                {back !== null && (back)}
                <h2
                    className="w-full font-montserrat font-semibold text-black dark:text-white text-lg flex-1">
                    {title}
                </h2>
            </div>
            <div className={'flex flex-row gap-2'}>
                {view && setView && (
                    <>
                        {view === 'grid' &&
                            <button type={"button"} onClick={() => setView('table')}><FontAwesomeIcon icon="fa-solid fa-table"/>
                            </button>}
                        {view === 'table' && <button type={"button"} onClick={() => setView('grid')}><FontAwesomeIcon
                            icon="fa-solid fa-table-cells-large"/></button>}
                    </>
                )}

                {children_blocks}
            </div>
        </div>
    )
}

export default SaasHeader