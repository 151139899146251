import Image from './image.svg'

const ErrorPage = () => {

    return (
        <main className={"flex min-h-full"} id={"cms-error-page"}>
            <div className="container max-auto flex justify-center">
                <div className={'flex flex-col flex-1 min-w-full md:flex-row justify-stretch items-center  text-gray-700'}>
                <div className="py-[100px]  flex-1">
                    <h1 className="text-8xl font-montserrat font-bold mb-6 text-blue-950">Oops!</h1>
                    <h3 className="text-3xl md:text-4xl font-light text-blaze-500 leading-normal mb-3">Sorry we have run into a problem</h3>

                    <p className="mb-8 text-gray-500 text-2xl">But dont worry, we should be back working shortly.</p>
                </div>
                <div className=" flex-1 justify-center text-center flex ">
                    <img src={Image} alt={"cat knocking over a vase"} title={"Error page"}/>
                </div>
                </div>
            </div>
        </main>
    )

}

export default ErrorPage