export const validateEmail = (email) => {
    // Basic email regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

export const validateRequired = (value) => {
    // Basic email regex pattern
    return value !== "";
};
