import {useEffect, useState} from "react";
import StepList from "./StepList";
import './styles.css'
import SaasBox from "../SaasBox";
import LocationComponent from "./LocationComponent";
import ProgressBar from "../ProgressBar";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useGetSessionMutation} from "../../app/api/spacesApi";
import AboutComponent from "./AboutComponent";
import PhotoComponent from "./PhotoComponent";
import useRoleRedirect from "../../hooks/useRoleRedirect";
import AvailabilityComponent from "./AvailabilityComponent";
import ServicesComponent from "./ServicesComponent";
import CancellationComponent from "./CancellationComponent";
import TermsComponent from "./TermsComponent";
import Loading from "../Loading";

// initial steps
const steps = {
    1: {
        key: 1,
        title: "Location",
        slug: "location",
        description: "Provide the location details of your space.",
        completed: false,
        active: false
    },
    2: {
        key: 2,
        slug: "about",
        title: "About the space",
        description: "Describe the features and amenities of your space.",
        completed: false,
        active: false
    },
    3: {
        key: 3,
        title: "Photos",
        slug: "photos",
        description: "Upload photos of the space.",
        completed: false,
        active: false
    },
    4: {
        key: 4,
        title: "Availability",
        slug: "availability",
        description: "Set the availability of your space.",
        completed: false,
        active: false
    },
    5: {
        key: 5,
        title: "Services",
        slug: "services",
        description: "List the services included with the space.",
        completed: false,
        active: false
    },
    6: {
        key: 6,
        title: "Cancellation and Refunds",
        slug: "cancellation",
        description: "What is your personal cancellation and refund policy.",
        completed: false,
        active: false
    },
    7: {
        key: 7,
        title: "Terms and policies",
        slug: "terms",
        description: "Accept Cohesii Terms and conditions.",
        completed: false,
        active: false
    }
};

const NewSpace = () => {

    useRoleRedirect('Space Owner')

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [completedSteps, setCompletedSteps] = useState(steps);
    const [sessionData, setSessionData] = useState({});
    const [activeStep, setActiveStep] = useState(null);

    const [getSession, {isLoading}] = useGetSessionMutation()

    const fetchSessionData = async () => {
        try {
            const sessionHash = searchParams.get('session');
            const response = await getSession({ session: sessionHash }).unwrap();
            const sessionData = response.data;

            if (sessionData) {
                // Update session data state
                setSessionData(sessionData || {});

                // Check for each step key if it exists in the session data and mark as completed
                const updatedSteps = {...completedSteps};

                Object.keys(updatedSteps).forEach(stepKey => {
                    if (sessionData[updatedSteps[stepKey].slug]) {
                        updatedSteps[stepKey].completed = true;
                    }
                });


                // Update completed steps state
                setCompletedSteps(updatedSteps || {});
            }

        } catch (error) {
            alert(error)
            navigate('/my-spaces');
        }
    };

    useEffect(() => {
        const sessionHash = searchParams.get('session');
        if (!sessionHash) {
            navigate('/my-spaces');
            return;
        }

        //reset the data
        const updatedSteps = {...completedSteps};
        Object.keys(updatedSteps).forEach(stepKey => {
            updatedSteps[stepKey].completed = false;
        });
        setCompletedSteps(updatedSteps);

        console.log(completedSteps)

        //fetch the remote session
        fetchSessionData();

    }, [searchParams]);



    const completedCount = Object.values(completedSteps).filter(step => step.completed).length;
    const progressPercentage = (completedCount / 7) * 100;


    const markStepAsCompleted = (stepKey) => {
        setCompletedSteps((prevSteps) => ({
            ...prevSteps,
            [stepKey]: {
                ...prevSteps[stepKey],
                completed: true
            }
        }));

        // Re-fetch the session data after marking the step as completed
        fetchSessionData();
    };


    const renderActiveStepComponent = () => {
        if (!activeStep) return null; // No active step initially

        const props = {
            markStepAsCompleted: markStepAsCompleted,
            session: searchParams.get('session'),
            setActiveStep: setActiveStep
        }

        switch (activeStep) {
            case 1:
                return <LocationComponent
                    defaultData={sessionData?.location || {}}
                    {...props}
                />;
            case 2:
                return <AboutComponent
                    defaultData={sessionData?.about}
                    {...props}
                />;
            case 3:
                return  <PhotoComponent
                    defaultData={sessionData?.photos}
                    {...props}
                />;
            case 4:
                return  <AvailabilityComponent
                    defaultData={sessionData?.availability}
                    {...props}
                />;
            case 5:
                return  <ServicesComponent
                    defaultData={sessionData?.services}
                    {...props}
                />;
            case 6:
                return  <CancellationComponent
                    defaultData={sessionData?.cancellation}
                    {...props}
                />;
            case 7:
                return  <TermsComponent
                    defaultData={sessionData?.terms}
                    {...props}
                />;
            default:
                return null;
        }
    };


    return (
        <>
            <ProgressBar progressPercentage={progressPercentage} />

            <Loading isLoading={isLoading} />

            {!isLoading && <SaasBox className={'mt-8'}>
                {!activeStep && (
                    <>
                        <StepList completedSteps={completedSteps} setActiveStep={setActiveStep}/>
                        <div className={'new-space__footer !mt-0'}>
                            <Link to={'/spaces/mine'} className={'btn__auth_back small'}>
                                Back
                            </Link>
                            {completedCount === 7 && (<button type={"submit"} className={'btn__auth_primary small'}>
                                Publish for approval
                            </button>)}
                        </div>
                    </>
                 )}

                {activeStep && (
                    <div className="active-step">
                        {renderActiveStepComponent()}
                    </div>
                )}

            </SaasBox>
            }
        </>
    )

}

export const StepFooter = ({setActiveStep, next}) => {

    return (

        <div className={'new-space__footer'}>
            <button className={'btn__auth_back small'} onClick={() => setActiveStep(null)}>
                Back
            </button>
            <button type={"submit"} className={'btn__auth_primary small'} onClick={next}>
                Next
            </button>
        </div>

    )
}

export default NewSpace