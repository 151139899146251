import {Form} from "react-router-dom";
import {Controller, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup"
import {Radio, Toggle} from "../Forms/CheckboxFields";
import {InputFieldGroup} from "../Forms/InputField";
import Select from "react-select";
import {useEffect} from "react";
import {StepFooter} from "./NewSpace";
import {toast} from "react-toastify";
import {useUpdateSessionMutation} from "../../app/api/spacesApi";
import {generateTimeOptions, daysOfWeek} from "./helpers";

const daySchema = yup.object().shape({
    open: yup.boolean().required('Open status is required'),
    all_hours: yup.string().when('open', {
        is: true,
        then: (daySchema) => daySchema.required('All hours or Set hours is required'),
        otherwise: (daySchema) =>  daySchema.nullable(),
    }),
    open_time: yup.string().when(['open', 'all_hours'], {
        is: (open, all_hours) => open && !all_hours,
        then: (daySchema) => daySchema
            .required('Open time is required')
            .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Open time must be in HH:mm format'),
        otherwise: (daySchema) => daySchema.nullable(),
    }),
    close_time: yup.string().when(['open', 'all_hours'], {
        is: (open, all_hours) => open && !all_hours,
        then: (daySchema) => daySchema
            .required('Close time is required')
            .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Close time must be in HH:mm format'),
        otherwise: (daySchema) => daySchema.nullable(),
    }),
});

const DayRow = ({day, handleOpenStatusChange, errors, register, control }) =>
{
    const timeOptions = generateTimeOptions();
    const value = useWatch({control, name:`${day}.open`});
    const allHours = useWatch({control, name:`${day}.all_hours`});

    return (
        <div className={'availability__row'} key={day}>
            <h3>{day}</h3>

            <Toggle
                checkedLabel={'Open'}
                uncheckedLabel={'Closed'}
                id={`${day}.open`}
                register={register}
                errors={errors[day]}
                onChangeCallback={() => {
                    handleOpenStatusChange(day)
                }}
            />
            {value && <>
                <InputFieldGroup>
                <Radio id={`${day}.all_hours`}
                       label={"All day (24 hrs)"}
                       showLabel={true}
                       value={true}
                       register={register}
                       errors={errors[day]}
                />
                <Radio id={`${day}.all_hours`}
                       label={"Set Hours"}
                       showLabel={true}
                       value={false}
                       register={register}
                       errors={errors[day]}
                />
            </InputFieldGroup>
            { (/false/).test(allHours) &&
                <>
                    <InputFieldGroup>
                <div className="input__field">
                    <label className="sr-only input__field_label normal" htmlFor={`${day}_open_time`}>Open Time *</label>
                    <Controller
                        control={control}
                        name={`${day}.open_time`}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Select options={timeOptions}
                                    ref={ref}
                                    id={`${day}_open_time`}
                                    value={timeOptions.find(c => c.value === (value || "07:00"))}
                                    onChange={val => onChange(val.value)}
                                    classNames={{
                                        control: () => `${errors[day]?.open_time ? "error" : ""} react-select`,
                                        option: () => "react-select__option",
                                    }}
                            />
                        )}
                    />
                    {errors[day]?.open_time && (
                        <p className="text-red-500 text-sm mt-1">{errors[day]?.open_time.message}</p>
                    )}

                </div>
                <p className={'ml-2 mr-2 items-center flex text-gray-500'}>to</p>
                <div className="input__field">
                    <label className="sr-only input__field_label normal" htmlFor={`${day}_close_time`}>Close Time *</label>
                    <Controller
                        control={control}
                        name={`${day}.close_time`}
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Select options={timeOptions}
                                    id={`${day}_close_time`}
                                    ref={ref}
                                    value={timeOptions.find(c => c.value === (value || "17:00"))}
                                    onChange={val => onChange(val.value)}
                                    classNames={{
                                        control: () => `${errors[day]?.close_time ? "error" : ""} react-select`,
                                        option: () => "react-select__option",
                                    }}
                            />
                        )}
                    />
                    {errors[day]?.close_time && (
                        <p className="text-red-500 text-sm mt-1">{errors[day]?.close_time.message}</p>
                    )}

                </div>

            </InputFieldGroup>
                </>
            }
            </>}


        </div>
    )
}

const AvailabilityComponent = ({setActiveStep, markStepAsCompleted, session, defaultData}) => {


    const [updateSession, {isLoading}] = useUpdateSessionMutation();

    const newDefaults = defaultData ??   daysOfWeek.reduce((acc, day) => {
        acc[day] = {open: true, all_hours: "true", open_time: '07:00', close_time: '17:00'};
        return acc;
    }, {})

    const {control, register, handleSubmit, setValue, getValues, formState: {errors}} = useForm({
        defaultValues: newDefaults,
        resolver: yupResolver(yup.object().shape(
            daysOfWeek.reduce((schema, day) => {
                schema[day] = daySchema;
                return schema;
            }, {})
        )),
        mode: 'onBlur'
    });


    useEffect(() => {
        getValues()
    }, [errors, getValues]);

    const onSubmit = async (data) => {
        if (!isLoading) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 4,
                    "data": {"availability": data}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success("Space Availability updated successfully")
                markStepAsCompleted(4)
                setActiveStep(null)
            }

        }
    };

    const handleOpenStatusChange = (day) => {
        const open = getValues(`${day}.open`);
        if (!open) {
            setValue(`${day}.all_hours`, true);
            setValue(`${day}.open_time`, '07:00');
            setValue(`${day}.close_time`, '17:00');
        }
    };


    return (
        <>
            <div className={'new-space__header'}>
                <h1 className={'title'}>What are your operating hours</h1>
                <p className={"strapline"}>Operating hours are the days and hours of the week that your space is open to
                    host bookings. Guests will not be able to book times outside of your operating hours.</p>
            </div>
            <div className={'new-space__location'}>
                <Form onSubmit={handleSubmit(onSubmit)} id="locationForm" className="space__location_form">
                    <fieldset className="input__fieldset">
                        {daysOfWeek.map((day) => (
                            <DayRow register={register}
                                    errors={errors}
                                    day={day}
                                    control={control} handleOpenStatusChange={handleOpenStatusChange} />
                        ))}
                    </fieldset>

                    <StepFooter
                        setActiveStep={setActiveStep}
                    />
                </Form>
            </div>
        </>
    )
}


export default AvailabilityComponent