const ProgressBar = ({progressPercentage, showPercent = true}) => {


    return (
        <div className={`w-full bg-gray-200 rounded-full h-4  ${showPercent ? 'mb-4' : ''}`}>
            <div
                className="bg-blue-500 h-4 rounded-full transition-all duration-300"
                style={{ width: `${progressPercentage}%` }}
            ></div>
            {showPercent &&
                <div className="text-right text-sm mt-1 text-gray-600">
                    {progressPercentage.toFixed(0)}%
                </div>
            }
        </div>
    );
};

export default ProgressBar;