import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useSsoCallbackMutation} from "../app/api/authApi";
import {loginSuccess} from "../app/features/auth/authSlice";
import Loading from "../Components/Loading";

const SsoCallback = () => {

    const [isLoading, setLoading] = useState(false);

    // create the dispatch hook
    const dispatch  = useDispatch();
    const navigate  =  useNavigate();

    // get the provider and search from the URL
    const { provider } = useParams();
    const [searchParams] = useSearchParams()

    const state = searchParams.get('state')

    //get the search query
    const { search } = useLocation();

    const [ssoCallback] = useSsoCallbackMutation()

    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) return;
        setLoading(true);
        hasRun.current = true;

        console.log(search, provider, state)

        const doCallback = async () => {
            const response =  await ssoCallback({ search, provider });
            setLoading(false);

            localStorage.removeItem("register");

            if (response.error) {
                if(state === 'login') {
                    navigate('/auth/login', {state: {formError: response.error?.data?.message}})
                }

                if(state === 'register') {
                    navigate('/auth/register', {state: {formError: response.error?.data?.message, step: 2}})
                }

                return;
            }

            if (response.data?.token) {
                dispatch(loginSuccess(response.data))
                navigate('/auth/2fa');
            }
        }

        doCallback()

    }, [dispatch, provider, search]);

    return  (
         <section className="register-container">
            <div className="register relative">
                <h1 className={'auth__title'}>Loading</h1>
                <Loading isLoading={isLoading}/>
            </div>
         </section>
    )
}

export default SsoCallback