import {loadStripe} from "@stripe/stripe-js";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import {useState} from "react";
import {useSelector} from "react-redux";
import {useAddBankAccountMutation} from "../../../../app/api/userApi";
import {toast} from "react-toastify";
import Form from "../../../../Components/Forms/Form";
import {Input, InputField} from "../../../../Components/Forms/InputField";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useModal} from "../../../../Components/Modal/modal-context";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


const bankAccountSchema = yup
    .object({
        country: yup.string().required(),
        account_holder_name: yup.string().required(),
        routing_number: yup.string().required(),
        account_number: yup.string().required(),

    })
    .required()

const AddBankAccountForm = ({country, name, callback = () => {}}) => {

    const [addBankCard, {isLoading}] = useAddBankAccountMutation()
    const stripe = useStripe();

    const defaultData = {
        country: country,
        account_holder_name: name
    }


    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
        resolver: yupResolver(bankAccountSchema),
    })


    const [loading, setLoading] = useState(false);


    const saveBank = async (data) => {
        setLoading(true);

        try {
            // Use Stripe's API to create a bank account token
            const { token, error } = await stripe.createToken('bank_account', {
                country: data.country,  // UK
                currency: 'GBP',
                account_holder_name: data.account_holder_name,
                account_holder_type: 'individual',  // Can be 'individual' or 'company'
                routing_number: data.sort_code,  // Sort code
                account_number: data.account_number,  // Account number
            });


            if (!isLoading) {
                // Send the token to your server to attach it to the space owner's account
                const response = await addBankCard({token: token.id})

                if (response.error) {
                    toast.error('An error occurred. Please try again.');
                } else {
                    toast.success('Bank account added successfully');
                }

                callback()
            }

        } catch (err) {
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Form id="payouts__add_bank_form" onSubmit={handleSubmit(saveBank)}>
            <h3 className={"section__title"}>Add a new bank account</h3>
            <p className={"section__strap"}>Add a bank account that your payments will be paid to.</p>

                <Input
                    register={register}
                    showLabel={false}
                    type={"hidden"}
                    id={"country"}
                    required={true}
                    autocomplete={"country"}
                    errors={errors}
                />


                <Input
                    inline={true}
                    register={register}
                    label={"Account Holder Name"}
                    type={"text"}
                    disabled={true}
                    id={"account_holder_name"}
                    required={true}
                    errors={errors}
                />


                <Input
                    inline={true}
                    register={register}
                    label={"Sort Code"}
                    type={"text"}
                    id={"routing_number"}
                    required={true}
                    errors={errors}
                />



                <Input
                    inline={true}
                    register={register}
                    label={"Account Number"}
                    type={"text"}
                    id={"account_number"}
                    required={true}
                    errors={errors}
                />

            <button type="submit" className={'btn__auth_primary small'} disabled={loading || !stripe}>
                {loading ? 'Processing...' : 'Add Bank Account'}
            </button>

        </Form>
    )
}

const AddBankAccount = (props) => {



    const user = useSelector((state) => state.auth.user);
    const {stripe_account_id: stripeAccountId} = user


    return (
        <div>
            {stripeAccountId && (
                <Elements stripe={stripePromise}>
                    <AddBankAccountForm {...props} />
                </Elements>
            )}
        </div>
    )
}

export default AddBankAccount