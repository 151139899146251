import Form from "../../Components/Forms/Form";
import {Link} from "react-router-dom";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {useLoginMutation} from "../../app/api/authApi";
import {useDispatch} from "react-redux";
import {loginSuccess} from "../../app/features/auth/authSlice";
import {validateEmail, validateRequired} from "../../Components/Forms/validation";

const LoginForm = ({setLoginError}) => {

    const [login] = useLoginMutation()

    const dispatch = useDispatch();

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [loginCreds, setLoginCreds] = useState({
        'email': null,
        'password': null,
        'remember_me': false,
    });

    const [errors, setErrors] = useState({
        email: null,
        password: null,
    });



    const validateFields = () => {
        const newErrors = {};

        if (!validateRequired(loginCreds.email) || !validateEmail(loginCreds.email)) {
            newErrors.email = "Valid email is required.";
        }

        if (!validateRequired(loginCreds.password) ) {
            newErrors.password = "Password is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const onChange = (value, key) => {
        setLoginCreds((prevCreds) => ({
            ...prevCreds,
            [key]: value,
        }));

        // Clear the error for the field being updated
        setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        setLoginError("")

        if (validateFields()) {
            // Proceed with form submission logic
            const response = await login({credentials: loginCreds})
            if (response.error) {
                setLoginError(response.error?.data?.message)
                return;
            }

            if (response.data.token) {
                dispatch(loginSuccess(response.data))
            }
        }
    };

    return (
        <Form onSubmit={onSubmit} id="login" className="login-form">



            <fieldset className={'input__fieldset'}>
                <div className={'input__field'}>
                    <label className={'input__field_label large'} htmlFor='email'>Email</label>
                    <input className={`input__field_input ${errors.email ? "error" : ""}`}
                           autoComplete={'email'}
                           type={'email'}
                           required={true}
                           value={loginCreds?.email || ""}
                           onChange={(e) => onChange(e.target.value, "email")}
                           name='email' placeholder='Enter your email'
                           id={'email'}/>

                    {errors.email && (
                        <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                    )}
                </div>
                <div className="input__field">
                    <label className="input__field_label large" htmlFor="password">
                        Password
                    </label>
                    <div className="relative">
                        <input
                            className={`input__field_input pr-10 ${errors.email ? "error" : ""}`}
                            autoComplete="password"
                            required={true}
                            onChange={(e) => onChange(e.target.value, "password")}
                            type={passwordVisible ? "text" : "password"} // Toggle input type
                            name="password"
                            value={loginCreds?.password || ""}
                            placeholder="Enter your password"
                            id="password"
                        />
                        <span
                            className="absolute right-0 top-0 mt-3 mr-3 cursor-pointer text-gray-600"
                            onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye}/>
                        </span>
                    </div>

                    {errors.password && (
                        <p className="text-red-500 text-sm mt-1">{errors.password}</p>
                    )}
                </div>

            </fieldset>

            <div className={'remember_row'}>
                <div className={'input__field_checkbox'}>
                    <label htmlFor={'remember_me'}>
                        <input className={'input__checkbox'}
                               type={'checkbox'} id={'remember_me'} name={'remember_me'} value={1}
                               checked={loginCreds.remember_me}
                               onChange={(e) => onChange(e.target.checked, "remember_me")}
                        />
                        <span>Remember for 30 days</span>
                    </label>
                </div>
                <Link className={'auth__link'} to={'/auth/forgot-password'} title={'Forgot password'}>
                    Forgot password?
                </Link>
            </div>

            <button type="submit" className={'btn__auth_primary'}>
                Sign in
            </button>


        </Form>
    )
}


export default LoginForm