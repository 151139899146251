export const setToken = (token, expires = null) => {
    localStorage.setItem('token', token);
    localStorage.setItem('token_expires', expires);
};

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expires');
};

export const getToken = () => {
    return localStorage.getItem('token');
};

export const getTokenExpires = () => {
    return localStorage.getItem('token_expires');
};