import {useGetSessionsMutation} from "../../../../app/api/spacesApi";
import {useEffect, useState} from "react";
import ProgressBar from "../../../../Components/ProgressBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {createSearchParams, useNavigate} from "react-router-dom";
import {useModal} from "../../../../Components/Modal/modal-context";
import DeleteSpace from "../DeleteSpace";
import {Helmet} from "react-helmet-async";
import SaasBox from "../../../../Components/SaasBox";

const NewSpaceList = () => {

    const { setModal } = useModal()

    const navigate = useNavigate();
    const [getSessions, {isLoading}] = useGetSessionsMutation();
    const [sessions,setSessions] = useState([]);

    const getSessionsAsync = async () => {
        const sessionsResponse = await getSessions({})
        setSessions(sessionsResponse.data)
    }

    useEffect(() => {
        if (!isLoading && sessions.length === 0) {
            getSessionsAsync()
        }

    }, []);


    if (isLoading || sessions?.length === 0) {
        return <></>
    }

    return (
        <SaasBox className={'mt-4'}>
            <Helmet>
                <title>Cohesii | Create a space</title>
            </Helmet>

            <h3 className={"section__title"}>New Spaces</h3>
            <p className={"section__strap"}>Dont forget to complete your new listings, if you know longer want to list this space feel free to remove it.</p>

            <div className={'sessions'}>
                {sessions?.map((session, index) => (
                    <div className={'session'} key={session.session_hash}>
                        <p className={'section__title'}>
                            {session.data?.about?.name || `New space ${index + 1}`}

                        </p>
                        <div className={"hidden sm:flex w-full"}>
                        <ProgressBar showPercent={false} progressPercentage={  (Object.keys(session?.data || {}).length / 7) * 100} />
                        </div>
                        <div className={'actions'}>
                            <button type={"button"} onClick={(e) => {
                                e.preventDefault()
                                navigate({
                                    pathname: "/spaces/create",
                                    search: createSearchParams({
                                        session: session.session_hash
                                    }).toString()
                                });
                            }}  className={"btn__auth_primary  !mt-0"}>
                                Resume
                            </button>

                            <button type={"button"} onClick={(e) => {
                                e.preventDefault()
                                setModal(<DeleteSpace hashId={session.session_hash} completeCallback={getSessionsAsync} />)
                            }} >
                                <FontAwesomeIcon icon="fa-solid fa-trash-can" className={'dark:text-white text-gray-500 size-6'} />
                            </button>
                        </div>
                    </div>
                ))}
            </div>

        </SaasBox>
    )

}

export default NewSpaceList