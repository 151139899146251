import {Helmet} from "react-helmet-async";
import SaasHeader from "../../../../Components/SaasHeader";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DetailsForm from "./DetailsForm";
import './styles.css'
import PhotoForm from "./PhotoForm";


const Info = () => {

    return (
        <>
            <Helmet>
                <title>Cohesii | Settings | Account details</title>
            </Helmet>
            <SaasHeader title={"Account details"} add={false} >
                <div className={'back-link'} slot={"back"}>
                    <Link to={'/account/overview'}>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className={'mr-2'} />
                        Back to settings
                    </Link>
                </div>
            </SaasHeader>

            <div className={'grid grid-cols-5 gap-8'}>
                <DetailsForm />
                <PhotoForm />
            </div>
        </>
    )


}

export default  Info