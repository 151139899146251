import './styles.css'

const Form = ({id, className, onSubmit, children}) => {

    return (
        <form id={id} className={className} onSubmit={onSubmit}>
            {children}
        </form>
    )


}

export default Form