import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL + '/auth/',
    credentials: 'include', // Important to include cookies
    prepareHeaders: (headers,  {getState}) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');

        console.log('Base URL:', process.env.REACT_APP_BASE_API_URL);
        console.log('Headers:', headers);
        return headers;
    },
});


export const authApi = createApi({
        reducerPath: 'authApi',
        baseQuery,
        endpoints: (builder) => ({
            getSsoUrlsByAction:
                builder.query({
                    query: (action = 'login') => `sso/urls/${action}`,
                    transformResponse: (response) => {
                        return response.data
                    },

                }),
            ssoCallback:
                builder.mutation({


                    query: ({ search = "", provider = 'google' }) => {
                        const searchParams = new URLSearchParams(search);
                        const savedData = JSON.parse(localStorage.getItem('register'));
                        if (savedData?.role) {
                            searchParams.set('role', savedData?.role);
                        }
                        const queryString = `?${searchParams.toString()}`;

                        return {
                            url: `sso/${provider}/callback${queryString}`,
                            method: 'GET',
                        };
                    },
                    transformResponse: (response) => response.data,
                }),
            logout:
                builder.mutation({
                    query:() => ({
                        url: `logout`,
                        method: 'GET'
                    }),
                    transformResponse: (response) => response.data
                }),
            twoFactorResend:
                builder.mutation({
                    query:() => ({
                        url: `twoFactor/resend`,
                        method: 'GET'
                    }),
                    transformResponse: (response) => response.data
                }),
            login:
                builder.mutation({
                    query:({credentials}) => ({
                        url: `login`,
                        method: 'POST',
                        body: credentials
                    }),
                    transformResponse: (response) => response.data
                }),
            register:
                builder.mutation({
                    query:({data}) => ({
                        url: `register`,
                        method: 'POST',
                        body: data
                    }),
                    transformResponse: (response) => response.data
                }),
            twoFactorConfirm:
                builder.mutation({
                    query:(code) => ({
                        url: `twoFactor`,
                        method: 'POST',
                        body: code,
                        headers: {
                            authorization: null,
                        },
                    }),

                    transformResponse: (response) => response.data
                })
    })
})

export const {
    useGetSsoUrlsByActionQuery,
    useSsoCallbackMutation,
    useLoginMutation,
    useRegisterMutation,
    useLogoutMutation,
    useTwoFactorResendMutation,
    useTwoFactorConfirmMutation
} = authApi