import SaasBox from "../../../../Components/SaasBox";
import Form from "../../../../Components/Forms/Form";
import * as yup from "yup";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input, InputFieldGroup} from "../../../../Components/Forms/InputField";
import PhoneInputWrapper, {isPhoneValid} from "../../../../Components/Forms/PhoneInput";
import {GenderSelectWrapper, PronounsSelectWrapper} from "../../../../Components/Forms/CustomFields";
import {useDispatch, useSelector} from "react-redux";
import {usePutMeMutation} from "../../../../app/api/userApi";
import {toast} from "react-toastify";
import {setUser} from "../../../../app/features/auth/authSlice";
import {useEffect} from "react";


const detailsSchema = yup.object().shape({
    name: yup
        .string()
        .required('Name is required'), // Proper string schema
    username: yup
        .string()
        .nullable(), // Make nullable explicitly
    email: yup
        .string()
        .email('Invalid email address') // Correct email validation message
        .required('Email is required'),
    phone_number: yup.string()
        .required('Phone number is required')
        .test('is-valid-phone', 'Phone number is not invalid', (value) => isPhoneValid(value, 'gb')),
    company: yup
        .string()
        .typeError('Company name is required'),
    gender: yup
        .string()
        .typeError('Gender must be a string'), // Proper string schema
    pronouns: yup
        .string()
        .typeError('Pronouns must be a string'), // Proper string schema
});
const DetailsForm = () => {

    const dispatch = useDispatch()

    const user = useSelector((state) => state.auth.user);

    const [putMe, {isLoading}] = usePutMeMutation()

    const filteredUser = Object.fromEntries(
        Object.entries(user || {}).filter(([_, value]) => value !== null)
    );

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty  },
        reset,
        setValue, trigger
    } = useForm({
        defaultValues: filteredUser,
        resolver: yupResolver(detailsSchema),
        mode: 'onBlur'
    })

    const submit = async (data) => {
        if (!isLoading) {
            const response =  await putMe({data:data})
            console.log(response.error)
            if (response.error) {
                toast.error("We could not save your details")
                return false;
            }

            if (response.data) {
                toast.success(`Details updated successfully`)

                // Dispatch user data only once after fetching
                dispatch(setUser(response.data));
            }
        }
    }



    return (
        <>
            <Form onSubmit={handleSubmit(submit)} id="personalDetailsForm" className="personal__details_form col-span-5 xl:col-span-3 ">
                <div className="">
                    <SaasBox>
                        <h3 className="section__title">Personal details</h3>
                        <p className={"section__strap"}>Enter your personal details relating to your account.
                            methods.</p>
                        <fieldset className={'details__personal'}>
                            <InputFieldGroup>
                                <Input
                                    register={register}
                                    label={"Full name"}
                                    id={"name"}
                                    type={"text"}
                                    required={true}
                                    errors={errors}
                                    icon={"fa-solid fa-user"}
                                />
                                <Input
                                    register={register}
                                    label={"Username"}
                                    id={"username"}
                                    type={"text"}
                                    required={false}
                                    errors={errors}
                                />
                            </InputFieldGroup>

                            <InputFieldGroup>
                                <Input
                                    register={register}
                                    label={"Email"}
                                    id={"email"}
                                    type={"email"}
                                    required={true}
                                    errors={errors}
                                    icon={"fa-solid fa-envelope"}
                                />

                                <Controller
                                    name="phone_number"
                                    control={control}
                                    render={({ field }) => (
                                        <PhoneInputWrapper
                                            {...field}
                                            label="Phone Number"
                                            required
                                            errors={errors}
                                            validateFieldChange={(name, value) => {
                                                setValue(name, value);
                                                trigger(name); // Trigger validation for the field
                                            }}
                                            handleInputChange={(e) => {
                                                setValue(e.target.name, e.target.value);
                                                trigger(e.target.name); // Trigger validation on change
                                            }}
                                        />
                                    )}
                                />
                            </InputFieldGroup>

                            <Input
                                register={register}
                                label={"Company"}
                                id={"company"}
                                type={"text"}
                                required={false}
                                errors={errors}
                            />

                            <InputFieldGroup>
                                <GenderSelectWrapper
                                    control={control}
                                    errors={errors}

                                />
                                <PronounsSelectWrapper
                                    control={control}
                                    errors={errors}
                                />
                            </InputFieldGroup>
                        </fieldset>

                        {isDirty &&
                            (
                                <div className={'new-space__footer flex-1'}>

                                    <button type={"button"} disabled={isLoading}
                                            className={'btn__auth_back small'} onClick={(e) => {reset()}}>
                                        Reset
                                    </button>
                                    <button type={"submit"} disabled={isLoading || !isValid}
                                            className={'btn__auth_primary small'}>
                                        {isLoading ? 'Processing...' : 'Create payout account'}
                                    </button>
                                </div>
                            )
                        }
                    </SaasBox>
                </div>

            </Form>
        </>
    )


}

export default DetailsForm;