import { useState, useEffect } from 'react';
import {getIpAndLocationFromLocalStorage, setIpAndLocationInLocalStorage} from "../app/helpers/local-storage";
import {useDispatch, useSelector} from "react-redux";
import {setIpAddress, setLocation} from "../app/features/auth/authSlice";

const useIpAndLocation = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Get data from Redux store if it exists
    const storedIp = useSelector(state => state.auth.ipAddress);
    const storedLocation = useSelector(state => state.auth.location);

    useEffect(() => {
        // If data is already in Redux store, no need to fetch

        if (storedIp && storedLocation) {
            setLoading(false);
            return;
        }

        const fetchIpAndLocation = async () => {
            // First, try to retrieve IP and location from local storage
            const storedData = getIpAndLocationFromLocalStorage();

            if (storedData) {
                // If found in local storage, dispatch it to Redux store
                dispatch(setIpAddress(storedData.ip));
                dispatch(setLocation(storedData.location));
                setLoading(false);
                return;
            }

            try {
                // If not found, fetch IP and location using the fetch API
                const response = await fetch('https://ipinfo.io/json'); // Replace with your API token
                if (!response.ok) {
                    throw new Error('Failed to fetch IP and location');
                }

                const data = await response.json();
                const { ip, city, region, country } = data;
                const location = `${city}, ${region}, ${country}`;

                // Dispatch to Redux store
                dispatch(setIpAddress(ip));
                dispatch(setLocation(location));

                // Store IP and location in local storage (with encryption if needed)
                setIpAndLocationInLocalStorage({ ip, location });
            } catch (err) {
                setError('Failed to fetch IP and location');
                console.error('Error fetching IP and location:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchIpAndLocation();
    }, [dispatch, storedIp, storedLocation]);

    return {storedIp, storedLocation, loading, error };
};

export default useIpAndLocation;