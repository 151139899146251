const SaasBox = ({className,children }) => {

    return (
        <div
            className={`${className ?? ''} saas-box`}>
            {children}
        </div>
    )
}

export default SaasBox