import React, {useState} from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Outlet} from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import Header from "./Components/Header";
import useFetchUser from "./hooks/useFetchUser";
import {ModalProvider} from "./Components/Modal/modal-context";
import useIpAndLocation from "./hooks/useIpAndLocation";
import Loading from "./Components/Loading";
import {DemoMode} from "./Components/Notices";

function App() {

    const loading = useFetchUser()
    const ipAndLocation = useIpAndLocation()

    const {storedIp, storedLocation} = ipAndLocation

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (

            <ModalProvider>
                <div className="App">
                    <div className="dark:bg-boxdark-2 dark:text-bodydark">
                        <div className="flex h-screen overflow-hidden">
                            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}  />

                            <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">

                                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}  />

                                {!loading &&
                                    <main className={'flex-1'}>
                                        <div className={'mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'}>
                                            <DemoMode />
                                            <Outlet/>
                                        </div>
                                    </main>
                                }

                                <Loading isLoading={loading} />

                                <footer id={"footer"}>
                                    <div className={'mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10'}>
                                        <div className={'flex flex-row'}>
                                            <p className={'text-gray-500 dark:text-gray-100 mb-3 mt-3 font-montserrat text-sm flex-1'}>
                                                © Copyright 2024 - Cohesii
                                            </p>
                                            <p className={'text-gray-500 dark:text-gray-100 mb-3 mt-3 font-montserrat text-sm'}>
                                                Accessed from: {storedLocation} {storedIp}
                                            </p>
                                        </div>
                                    </div>

                                </footer>
                            </div>
                        </div>


                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick

                    />
                </div>
            </ModalProvider>
    );
}

export default App;
