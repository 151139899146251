import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import ClickOutside from "../ClickOutside";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLogoutMutation} from "../../app/api/authApi";
import {logout} from "../../app/features/auth/authSlice";

const UserDropdown = () => {
    const dispatch = useDispatch();

    const basePath = process.env.REACT_APP_STORAGE_URL;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const user = useSelector((state) => state.auth.user);

    const [logoutRun] = useLogoutMutation()

    const logoutAction = async () => {
        const response = await logoutRun({});
        if (!response.error) {
            dispatch(logout())

        }
    }

    const getPhotoUrl = (photo) => {
        const isFullUrl = photo?.startsWith('http://') || photo?.startsWith('https://');
        console.log(photo)
        // If it's a full URL, return it as is, otherwise, prefix with a base URL
        return isFullUrl ? photo : `${basePath}${photo}`;
    };

    return (
        <ClickOutside onClick={() => setDropdownOpen(false)} className="user-dropdown">
            <Link
                onClick={(e) => {e.preventDefault(); setDropdownOpen(!dropdownOpen)}}
                className="user-dropdown__link"
                to="#"
            >
                <span className="hidden text-right lg:block">
                  <span className="block text-sm font-medium text-black dark:text-white">
                    {user?.name}
                  </span>
                  <span className="block text-xs text-gray-400 dark:text-gray-100">{user?.company}</span>
                </span>

                <span className="h-12 w-12 rounded-full overflow-hidden">
                  <img src={getPhotoUrl(user?.photo)} alt="User" />
                </span>

                <FontAwesomeIcon icon={'fa-solid fa-chevron-down'} className={'hidden fill-current sm:block'}/>
            </Link>

            {dropdownOpen && (
                <div
                    className={`user-dropdown__dropdown`}
                >
                    <ul>
                        <li>
                            <Link
                                to="/profile"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-user" />
                                My Profile
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/settings"
                            >
                                <FontAwesomeIcon icon="fa-solid fa-gear" />
                                Account Settings
                            </Link>
                        </li>
                    </ul>
                    <button onClick={logoutAction}>
                        <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
                        Log Out
                    </button>
                </div>
            )}
        </ClickOutside>
    );
}

export default UserDropdown