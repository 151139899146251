import {InputField} from "./InputField";
import { useState} from "react";

export const Toggle = ({
                           id,
                           name = null,
                           showLabel = false,
                           showError = false,
                           checkedLabel,
                           uncheckedLabel,
                           register,
                           errors,
                           onChangeCallback = (e) => {}
                       }) => {

    // Use state to track the checked status
    const [isChecked, setIsChecked] = useState(false);

    // Handle the onChange event and update the state
    const onChange = (e) => {
        onChangeCallback(e);
        setIsChecked(e.target.checked);
    };

    return (
        <InputField>
            <div className="toggle">
                <label htmlFor={id.replace(".", "_")} className={'flex-row flex'}>
                    <input
                        id={id.replace(".", "_")}
                        type="checkbox"
                        {...register(name ?? id, {
                            onChange: (e) => onChange(e),  // Update state on change
                        })}
                    />
                    {/* Conditionally render the label based on isChecked */}
                    {showLabel && (
                        <div className={'text-sm '}>
                            {isChecked ? checkedLabel : uncheckedLabel}
                        </div>
                    )}
                </label>
            </div>
        </InputField>
    );
};

export const Radio = ({
                           id,
                           name = null,
                           showLabel = false,
                           label,
                           value=''|0,
                           register,
                           errors,
                           onChangeCallback = (e) => {}
                       }) => {


    return (
        <InputField>
                <label htmlFor={id.replace(".","_")} >
                    <input id={id.replace(".","_")} value={value} type="radio" {...register(name ?? id, {
                        onChange: (e) => {
                            onChangeCallback(e)
                        },
                    })}/>
                    {showLabel && <span>{label}</span>}
                </label>
        </InputField>
    )
}

export const Checkbox = ({
                          id,
                          name = null,
                          showLabel = false,
                          label,
                          value=''|0,
                          register,
                          errors,
                          onChangeCallback = (e) => {}
                      }) => {


    return (
        <InputField>
            <label htmlFor={id.replace(".","_")} >
                <input id={id.replace(".","_")} value={value}  type="checkbox" {...register(name ?? id, {
                    onChange: (e) => {
                        onChangeCallback(e)
                    },
                })}/>
                {showLabel && <span className={'ml-2'}>{label}</span>}
            </label>
        </InputField>
    )
}