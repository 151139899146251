import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import {InputField, InputFieldGroup} from "./InputField";


// Helper function to generate options
// Helper function to generate day options
const generateDayOptions = (daysInMonth) => {
    return Array.from({ length: daysInMonth }, (_, i) => ({
        value: i + 1,
        label: String(i + 1).padStart(2, '0'),
    }));
};

// Helper function to generate month options
const generateMonthOptions = () => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return months.map((month, index) => ({
        value: index + 1,
        label: month
    }));
};

// Helper function to generate year options
const generateYearOptions = (startYear) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - startYear + 1 }, (_, i) => ({
        value: currentYear - i,
        label: currentYear - i,
    }));
};

// DateField Component
const DateField = ({ control, errors, label }) => {
    // Options for day, month, and year dropdowns
    const dayOptions = generateDayOptions(31);
    const monthOptions = generateMonthOptions();
    const yearOptions = generateYearOptions(1950);

    const hasErrors = errors.dob_day || errors.dob_month || errors.dob_year;
    const errorMessage = hasErrors ? 'Please correct the errors above.' : '';

    return (
        <div className={'flex flex-col gap-2 flex-1'}>
            <label className="input__field_label normal ">{label} *</label>
            <InputFieldGroup>
                <InputField>
                    <Controller
                        control={control}
                        name="dob_day"
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Select
                                options={dayOptions}
                                ref={ref}
                                value={dayOptions.find(option => option.value === value)}
                                onChange={val => onChange(val.value)}
                                classNames={{
                                    control: () => `${errors.dob_day ? "error" : ""} react-select`,
                                    option: () => "react-select__option",
                                }}
                            />
                        )}
                    />
                </InputField>

                <InputField>

                    <Controller
                        control={control}
                        name="dob_month"
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Select
                                options={monthOptions}
                                ref={ref}
                                value={monthOptions.find(option => option.value === value)}
                                onChange={val => onChange(val.value)}
                                classNames={{
                                    control: () => `${errors.dob_month ? "error" : ""} react-select`,
                                    option: () => "react-select__option",
                                }}
                            />
                        )}
                    />
                </InputField>

                <InputField>
                    <Controller
                        control={control}
                        name="dob_year"
                        render={({field: {onChange, onBlur, value, ref}}) => (
                            <Select
                                options={yearOptions}
                                ref={ref}
                                value={yearOptions.find(option => option.value === value)}
                                onChange={val => onChange(val.value)}
                                classNames={{
                                    control: () => `${errors.dob_year ? "error" : ""} react-select`,
                                    option: () => "react-select__option",
                                }}
                            />
                        )}
                    />
                </InputField>
            </InputFieldGroup>

            {/* Display single error message at the bottom */}
            {hasErrors && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
        </div>
    );
};

export default DateField;