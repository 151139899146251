import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import LogoIcon from './../../assets/Cohesii-icon-light.svg'
import './styles.css'
import UserDropdown from "./UserDropdown";
import DarkModeSwitcher from "./DarkModeSwitcher";
const Header = ({
                    sidebarOpen = false,
                    setSidebarOpen = () => {
                    }
                }
) => {


    return (
        <header id={"dashHeader"}>
            <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
                <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
                    <button
                        aria-controls="sidebar"
                        onClick={(e) => {
                            e.stopPropagation();
                            console.log('clicked')
                            setSidebarOpen(!sidebarOpen);
                        }}
                        className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
                    >
                        <FontAwesomeIcon icon="fa-solid fa-bars"/>
                    </button>

                    <Link className="block flex-shrink-0 lg:hidden" to="/">
                        <img src={LogoIcon} alt="Logo"/>
                    </Link>

                </div>
                <div></div>
                <div className="flex items-center self-end gap-3 2xsm:gap-7">
                    <div className="flex items-center gap-3 2xsm:gap-7">
                        <ul className="flex items-center gap-2 2xsm:gap-4">
                            <DarkModeSwitcher/>
                        </ul>
                        <UserDropdown/>
                    </div>
                </div>
            </div>
        </header>

)
}

export default Header