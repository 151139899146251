import Dictaphone from "./microphone";
import {useState} from "react";
import {useQueryMutation} from "../../../app/api/aiApi";
import Prompts from "./Prompts";
import GridItem from "../../../Components/Spaces/GridItem";
import ImageDragDrop from "../../../Components/Forms/Image";

const Recommendation = () => {

    const [response, setResponse] = useState(null);
    const [query, setQuery] = useState("");

    const [queryMutation, {isLoading}] = useQueryMutation()

    const reset = () => {
        setQuery("");
        setResponse(null);
    }

    const setQueryAndSearch = (e, query = '') => {
        setQuery(query);
        search(query)

    }

    const search = async (currentQuery = null) => {
        if (!isLoading) {
            const response = await queryMutation({query: currentQuery || query})
            if (response.data) {
                setResponse(response.data)
            }
        }
    }

    return (
        <div className={'product-recommendation'}>
            {isLoading && (
                <div className={'flex flex-row gap-4 items-center py-6'}>
                    <p className={'text-lg text-gray-500 mb-4 mt-4'}>Hang tight we just looking for you.</p>
                    <div className="chat-bubble ">
                        <div className="typing justify-center content-center">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                </div>
            )}

            {(!response && !isLoading) && (
                <>
                    <p className={'text-gray-500 mb-3 mt-3'}>Enter your search phrase, or click the microphone to say
                        what your looking for. </p>
                    <div className="search-container">
                        <div className="search-question">
                            <svg version="1.1" id="search_question"
                                 viewBox="0 0 37.596 37.596" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="M37.595,33.595V0H4.001c-2.209,0-4,1.791-4,4v33.596h33.595
                                                    C35.804,37.595,37.595,35.804,37.595,33.595z M17.173,33.631c-1.87,0-3.391-1.598-3.391-3.469c0-1.871,1.521-3.43,3.391-3.43
                                                    c1.91,0,3.431,1.559,3.431,3.43C20.604,32.033,19.084,33.631,17.173,33.631z M23.254,19.209c-1.555,1.148-2.996,2.065-3.6,3.501
                                                    c-0.477,1.137-1.259,2.19-2.492,2.19h-2.248c-0.196-1.95,0.077-3.586,0.934-5.028c1.324-2.299,4.795-3.702,4.795-7.132
                                                    c0-1.676-1.053-2.845-3.04-2.845c-2.067,0-3.86,1.247-5.185,2.885l-1.555-3.928c-0.453-1.147-0.066-2.741,1.009-3.343
                                                    c1.996-1.119,4.233-1.599,6.627-1.539c4.911,0.195,8.341,2.728,8.419,7.483C26.919,14.376,26.1,17.105,23.254,19.209z"/>
                                </g>
                            </svg>
                        </div>

                        <input aria-label="search-label-mob" placeholder="Explain what sort of space your looking for?" type="text"
                               className="search-field "
                               value={query}
                               onChange={event => {
                                   setQuery(event.target.value)
                               }}
                               onKeyDown={event => {
                                   if (event.key === 'Enter') {
                                        search()
                                   }
                               }}/>
                        <div className="dictaphone">
                            <Dictaphone setQuery={setQuery}/>
                        </div>
                    </div>
                     <div className={`example-prompts`}>
                        <Prompts setQueryAndSearch={setQueryAndSearch} />
                         <div className={'search-by-image flex-1 flex flex-col pl-8 pb-3'}>
                             <h3 className={'text-gray-800 text-2xl font-bold mb-2'}>Search your space by image</h3>
                             <p className={'text-gray-500 mb-8'}>If you know what your looking for but not sure how to describe it, why not upload an image that's similar. Our AI will find something for you.</p>
                            <ImageDragDrop classes={'flex-1'} />
                         </div>
                     </div>
                </>
            )}

            {response && !isLoading && (
                <>
                    <div className={"new-question"}>
                        <button className={"btn__auth_primary text-nowrap !w-auto"} onClick={reset}>
                            <svg viewBox="-1.7 0 20.4 20.4" className={"w-4 fill-white"}
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.416 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.916 7.917zm-2.958.01a.792.792 0 0 0-.792-.792H9.284V6.12a.792.792 0 1 0-1.583 0V9.5H4.32a.792.792 0 0 0 0 1.584H7.7v3.382a.792.792 0 0 0 1.583 0v-3.382h3.382a.792.792 0 0 0 .792-.791z"/>
                            </svg>
                            New Question
                        </button>
                    </div>
                    <p className={'text-gray-500 mt-4 mb-4 flex flex-col gap-4 mb-12'} dangerouslySetInnerHTML={{__html: response.message}}/>
                    <div className={'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4'}>
                        {response.spaces.map((space, index) => (
                            <>
                                <GridItem key={`space-${index}`} id={space.id} space={space.properties} />
                            </>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default Recommendation