import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL + '/user/',
    credentials: 'include', // Important to include cookies
    prepareHeaders: (headers,  {getState}) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        return headers;
    },
});


export const userApi = createApi({
        reducerPath: 'userApi',
        baseQuery: baseQuery,
        endpoints: (builder) => ({
            getMe:
                builder.mutation({
                    query:() => ({
                        url: `me`,
                        method: 'GET',
                    }),

                    transformResponse: (response) => response.data
                }),
            putMe:
                builder.mutation({
                    query:({data}) => ({
                        url: `me`,
                        method: 'PUT',
                        body: data
                    }),

                    transformResponse: (response) => response.data
                }),
            getPaymentDetails:
                builder.mutation({
                    query:({type}) => ({
                        url: `billing/details/${type}`,
                        method: 'GET',
                    }),

                    transformResponse: (response) => response.data
                }),

            savePaymentDetails:
                builder.mutation({
                    query:({id = null, data={}}) => ({
                        url: `billing/details/${id ?? ''}`,
                        method: 'PUT',
                        body: data
                    }),

                    transformResponse: (response) => response.data
                }),
            addBankAccount:
                builder.mutation({
                    query:({token = ""}) => ({
                        url: `billing/bank`,
                        method: 'POST',
                        body: {
                            token: token
                        }
                    }),

                    transformResponse: (response) => response.data
                }),
            addBankCard:
                builder.mutation({
                    query:({token = ""}) => ({
                        url: `billing/card`,
                        method: 'POST',
                        body: {
                            token: token
                        }
                    }),

                    transformResponse: (response) => response.data
                }),
            deleteBankAccount:
                builder.mutation({
                    query:({id = ""}) => ({
                        url: `billing/bank/${id}`,
                        method: 'DELETE',
                    }),

                    transformResponse: (response) => response.data
                }),

    })
})

export const {
    useGetMeMutation,
    usePutMeMutation,
    useGetPaymentDetailsMutation,
    useSavePaymentDetailsMutation,
    useAddBankAccountMutation,
    useAddBankCardMutation,
    useDeleteBankAccountMutation,
} = userApi