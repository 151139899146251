import Form from "../Forms/Form";
import {useMemo} from "react";
import countryList from "react-select-country-list";
import Select from 'react-select'
import {StepFooter} from "./NewSpace";
import {Input, InputField, InputFieldGroup} from "../Forms/InputField";

import * as yup from "yup"
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUpdateSessionMutation} from "../../app/api/spacesApi";
import {toast} from "react-toastify";

const locationSchema = yup
    .object({
        country: yup.string().required(),
        address: yup.string().required(),
        suite: yup.string().optional(),
        city: yup.string().required(),
        postcode: yup.string().required(),
    })
    .required()

const LocationComponent = ({setActiveStep, markStepAsCompleted, session, defaultData}) => {

    const [updateSession, {isLoading}] = useUpdateSessionMutation();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
        resolver: yupResolver(locationSchema),
    })

    const options = useMemo(() => countryList().getData(), [])

    const submit = async (data) => {

        if (!isLoading) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 1,
                    "data": {"location": data}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success("Space location updated successfully")
                markStepAsCompleted(1)
                setActiveStep(null)
            }

        }
    }

    return (
        <>
            <div className={'new-space__header'}>
                <h1 className={'title'}>Address</h1>
                <p className={"strapline"}>Your space address will not be fully public, we only use the city and postcode to display an estimated location.</p>
            </div>
            <div className={'new-space__location'}>
                <Form onSubmit={handleSubmit(submit)} id="locationForm" className="space__location_form">
                    <fieldset className="input__fieldset">

                        <InputField>
                            <label className="input__field_label normal" htmlFor="country">Country *</label>
                            <Controller
                                control={control}
                                name={"country"}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select options={options}
                                            ref={ref}
                                        value={options.find(c => c.value === value)}
                                        onChange={val => onChange(val.value)}
                                        classNames={{
                                            control: () => `${errors.country ? "error" : ""} react-select`,
                                            option: () => "react-select__option",
                                        }}
                                    />
                                )}
                            />
                            {errors.country && (
                                <p className="text-red-500 text-sm mt-1">{errors.country.message}</p>
                            )}
                        </InputField>


                        <InputFieldGroup>
                            <Input
                                register={register}
                                label={"Address"}
                                id={"address"}
                                required={true}
                                autocomplete={"street-address"}
                                errors={errors}
                            />

                            <Input
                                register={register}
                                label={"Apartment, Floor, Building Number"}
                                id={"suite"}
                                required={true}
                                autocomplete={"address-level1"}
                                errors={errors}
                            />


                        </InputFieldGroup>
                        <InputFieldGroup>
                            <Input
                                register={register}
                                label={"City"}
                                id={"city"}
                                required={true}
                                errors={errors}
                            />

                            <Input
                                register={register}
                                label={"Postcode"}
                                id={"postcode"}
                                required={true}
                                errors={errors}
                            />

                        </InputFieldGroup>
                    </fieldset>

                    <StepFooter setActiveStep={setActiveStep}  />
                </Form>
            </div>

        </>
    )
}

export default LocationComponent;