import './styles.css';
import {useEffect, useState} from "react";
import {TwoFactor} from "../TwoFactor/TwoFactorView";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {StepOne} from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import {useRegisterMutation} from "../../app/api/authApi";
import {loginSuccess, logout} from "../../app/features/auth/authSlice";
import Loading from "../../Components/Loading";
import {Helmet} from "react-helmet-async";
import {decryptData} from "../../app/helpers/crypto";

const RegisterView = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    // Initialize the state
    const [multiStepData, setMultiStepData] = useState({
        role: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        password: null,
        confirmPassword: null,
    });

    const [step, setStep] = useState(1);
    const [formError, setFormError] = useState(location?.state?.formError ?? null);
    const [registerTrigger, setRegisterTrigger] = useState(false);

    const [register, {isLoading}] = useRegisterMutation();
    const needsTwoFactor = useSelector((state) => state.auth.needsTwoFactor);

    // Function to set multi-step data by key
    const setMultiStepDataByKey = (key, value) => {
        setMultiStepData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const [tokenProcessed, setTokenProcessed] = useState(false);

    // Load initial state from URL parameters or localStorage
    useEffect(() => {
        const token = searchParams.get('token');
        const roleFromParams = searchParams.get('role');

        // Check if token is present for partially registered users
        if (token) {
            const decryptedData = decryptData(token.replace(/\s/g, '+'));
            console.log(decryptedData)
            if (decryptedData) {
                localStorage.setItem('register', JSON.stringify(decryptedData));
                setMultiStepData((prevData) => ({
                    ...prevData,
                    ...decryptedData, // Decrypted data may include role, name, email, etc.
                }));
                setStep(2); // Start at step 2 for partially registered users

                setTokenProcessed(true);
            }
        } else {
            // No token, load data from localStorage if available
            const savedData = JSON.parse(localStorage.getItem('register'));
            if (savedData && savedData.role !== null) {
                setMultiStepData((prevData) => ({
                    ...prevData,
                    ...savedData,
                }));
                setStep(location?.state?.step ?? 2); // Start from step 2 if user is returning with saved data
            } else if (roleFromParams) {
                // If there's a role from the searchParams, skip to step 2
                setMultiStepData((prevData) => ({
                    ...prevData,
                    role: roleFromParams,
                }));
                setStep(2);
            }
        }
    }, [searchParams]);

    useEffect(() => {
        if (tokenProcessed) {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete('token');
            navigate({ search: newSearchParams.toString() }, { replace: true });
        }
    }, [tokenProcessed, searchParams, navigate]);

    // Save multi-step data to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("register", JSON.stringify(multiStepData));
    }, [multiStepData]);

    // Handle form submission (step 3 -> API call)
    useEffect(() => {
        if (registerTrigger) {
            const registerAction = async () => {
                const response = await register({
                    data: {
                        email: multiStepData.email,
                        role: multiStepData.role,
                        password: multiStepData.password,
                        password_confirmation: multiStepData.confirmPassword,
                        firstName: multiStepData.firstName,
                        lastName: multiStepData.lastName,
                        company: multiStepData.company,
                    },
                });

                if (response.error) {
                    setFormError(response.error?.data?.message);
                    setStep(1);
                    setRegisterTrigger(false);
                    return;
                }

                if (response.data?.token) {
                    setMultiStepData({});
                    setStep(4); // Move to Two-Factor Auth step
                    dispatch(loginSuccess(response.data));
                    localStorage.removeItem("register");
                }
            };

            registerAction().then(() => setRegisterTrigger(false));
        }
    }, [registerTrigger, multiStepData, dispatch, register]);

    const backAction = () => {
        dispatch(logout())
    }


    return (
        <>
            <Helmet>
                <title>Cohesii | Register to the portal</title>
            </Helmet>
            <section className="register-container">
                <div className="register relative">
                    <div className="steps">
                        <div className={`step ${step > 1 ? 'complete' : ''}`}></div>
                        <div className={`step ${step > 2 ? 'complete' : ''}`}></div>
                        <div className={`step ${step > 3 ? 'complete' : ''}`}></div>
                        <div className={`step ${step > 4 ? 'complete' : ''}`}></div>
                    </div>

                    {registerTrigger &&

                        <Loading isLoading={isLoading} />

                    }

                    {step === 1 && (
                        <StepOne
                            currentRole={multiStepData.role}
                            registerError={formError}
                            setStep={setStep}
                            setRegisterError={setFormError}
                            setRegisterData={setMultiStepDataByKey}
                        />
                    )}

                    {step === 2 && (
                        <StepTwo
                            registerError={formError}
                            setStep={setStep}
                            setRegisterError={setFormError}
                            multiStepData={multiStepData}
                            setRegisterData={setMultiStepDataByKey}
                        />
                    )}

                    {step === 3 && (
                        <StepThree
                            registerError={formError}
                            setStep={setStep}
                            setRegisterError={setFormError}
                            setRegister={setRegisterTrigger}
                            setRegisterData={setMultiStepDataByKey}
                        />
                    )}

                    {needsTwoFactor && step === 4 && <TwoFactor backAction={backAction} formError={formError} setFormError={setFormError} />}
                </div>
            </section>
        </>
    );
};

export default RegisterView;