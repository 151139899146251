import { BlobServiceClient, StorageSharedKeyCredential } from "@azure/storage-blob";

// Enter your storage account name and shared key
const account = "2q0c";
const sas = "sv=2023-01-03&spr=https%2Chttp&st=2024-08-18T17%3A20%3A13Z&se=2024-08-19T17%3A20%3A13Z&sr=c&sp=racwl&sig=y7o3Lot3rq8jfEL6bolknLm1bUFtaBdQooONFm6W8pE%3D";

async function uploadImage(containerName, file, directory = 'spaces/media/') {

    const blobServiceClient = new BlobServiceClient(
        `https://${account}.blob.core.windows.net?sv=2023-01-03&spr=https%2Chttp&st=2024-08-18T17%3A44%3A58Z&se=2025-08-19T17%3A44%3A00Z&sr=c&sp=racwdxlf&sig=YxJcFsgu1MmyNoBXcWl5AVwmPMPO0RxputNolZkyyAI%3D`,
    );

    const containerClient = blobServiceClient.getContainerClient(containerName);


    const blobClient = containerClient.getBlobClient(`${directory}${file.name}`);
    const blockBlobClient = blobClient.getBlockBlobClient();
    const result = await blockBlobClient.uploadData(file, {
        blobHTTPHeaders: {
            blobContentType: file.type,
        },
    });

    console.log(`Upload of file '${file.name}' completed`);

    return `${directory}${file.name}`
}

export default uploadImage