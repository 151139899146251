import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {fetchCsrfToken, store} from './app/store'
import { Provider } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import ErrorPage from "./Components/Error/Error";
import Auth from "./Auth";
import LoginView from "./routes/Login/LoginView";
import SsoCallback from "./routes/SsoCallback";
import TwoFactorView from "./routes/TwoFactor/TwoFactorView";
import RegisterView from "./routes/Register/RegisterView";
import MySpaces from "./routes/Spaces/MySpaces/MySpaces";
import NewSpace from "./Components/NewSpace/NewSpace";
import {HelmetProvider} from "react-helmet-async";
import Calculator from "./routes/Spaces/Calculator/Calculator";
import Match from "./routes/Spaces/Match";
import Payouts from "./routes/User/Settings/Billing/Payouts";
import Settings from "./routes/User/Settings";
import Payments from "./routes/User/Settings/Billing/Payments";
import Info from "./routes/User/Settings/Info";
import OwnerDashboard from "./routes/Dashboard/OwnerDashboard";

library.add(fab, fas)

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                loader: async () => redirect("/dashboard")
            },
            {
                path: "dashboard",
                element: <OwnerDashboard />,
            },
            {
                path: "spaces",
                children: [
                    {
                        path: "mine",
                        element: <MySpaces />,

                    },
                    {
                        path: "create",
                        element: <NewSpace />
                    },
                    {
                        path: "calculator",
                        element: <Calculator />
                    },
                    {
                        path: "match",
                        element: <Match />
                    }

                ],
            },
            {
                path: "account/overview",
                element: <Settings />
            },
            {
                path: "account",
                children: [
                    {
                        path: "billing",
                        children: [
                            {
                                path: "details",
                                element: <Payments />
                            },
                            {
                                path: "invoices",
                            }
                        ]
                    },
                    {
                        path: "payout",
                        children: [
                            {
                                path: "details",
                                element: <Payouts />
                            },
                            {
                                path: "transactions",
                            }
                        ]
                    },
                    {
                        path: "details",
                        element: <Info />
                    }
                    ,
                    {
                        path: "notifications",
                    }
                ]
            }
        ]
    },
    {
        path: "/auth",
        element: <Auth />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                loader: async () => redirect("/auth/login")
            },
            {
                path: "sso/:provider/callback",
                element: <SsoCallback />,
            },
            {
                path: "login",
                element: <LoginView />,
            },
            {
                path: "register",
                element: <RegisterView />,
            },
            {
                path: "2fa",
                element: <TwoFactorView />,
            },
        ],
    },
]);

store.dispatch(fetchCsrfToken());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HelmetProvider>
          <Provider store={store}>
            <RouterProvider router={router} />
          </Provider>
      </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
