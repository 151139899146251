import {Controller} from "react-hook-form";
import {InputField} from "./InputField";
import Select from "react-select";

const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Non Binary', label: 'Non Binary' },
    { value: 'Transgender', label: 'Transgender' },
    { value: 'Gender Queer', label: 'Gender Queer' },
    { value: 'Gender Queer', label: 'Gender Queer' },
    { value: 'Gender Fluid', label: 'Gender Fluid' },
    { value: 'Other', label: 'Not Disclosed' },
];

const pronounOptions = [
    { value: 'He/Him', label: 'He/Him' },
    { value: 'She/Her', label: 'She/Her' },
    { value: 'They/Them', label: 'They/Them' },
    { value: 'Other', label: 'Not Disclosed' },
];

export const GenderSelectWrapper = ({
                                 label = 'Gender',
                                 required = false,
                                 name = 'gender',
                                 ariaLabel = 'gender',
                                 control,
                                 errorMessage = `${label} is required`,
                                 errors // Pass the errors object
                             }) => {
    return (
        <SelectWrapper errors={errors}
                       ariaLabel={ariaLabel}
                       label={label}
                       required={required}
                       name={name}
                       control={control}
                       errorMessage={errorMessage}
                       options={genderOptions}
        />
    );
};

export const PronounsSelectWrapper = ({
                                        label = 'Pronouns',
                                        required = false,
                                        name = 'pronouns',
                                        ariaLabel = 'pronouns',
                                        control,
                                        errorMessage = `${label} is required`,
                                        errors // Pass the errors object
                                    }) => {
    return (
        <SelectWrapper errors={errors}
                       ariaLabel={ariaLabel}
                       label={label}
                       required={required}
                       name={name}
                       control={control}
                       errorMessage={errorMessage}
                       options={pronounOptions}
        />
    );
};


export const SelectWrapper = ({
                                        label = '',
                                        required = false,
                                        name = '',
                                        ariaLabel = '',
                                        control,
                                        errorMessage = `${label} is required`,
                                        options = {},
                                        errors // Pass the errors object
                                    }) => {
    const genderError = errors[name]; // Retrieve error for the gender select

    return (
        <InputField>
            <label className="input__field_label normal" htmlFor={name}>
                {label} {required && <span className="text-red-500">*</span>}
            </label>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                rules={{ required: required ? errorMessage : false }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                        options={options}
                        ref={ref}
                        id={name}
                        aria-label={ariaLabel}
                        value={options.find(option => option.value === value)}
                        onChange={selectedOption => onChange(selectedOption.value)}
                        onBlur={onBlur}
                        className={`react-select ${genderError ? 'error' : ''}`} // Apply error class if needed
                        classNamePrefix="react-select"
                    />
                )}
            />
            {genderError && (
                <p className="text-red-500 dark:text-red-200 text-sm mt-1 mb-2">
                    {genderError.message || errorMessage}
                </p>
            )}
        </InputField>
    );
};

