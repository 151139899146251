import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL + '/spaces/',
    credentials: 'include', // Important to include cookies
    prepareHeaders: (headers,  {getState}) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');
        return headers;
    },
});


export const spacesApi = createApi({
        reducerPath: 'spacesApi',
        baseQuery: baseQuery,
        endpoints: (builder) => ({
            getSessions:
                builder.mutation({
                    query:() => ({
                        url: `session`,
                        method: 'GET',
                    }),

                    transformResponse: (response) => response.data
                }),
            getSession:
                builder.mutation({
                    query:({session}) => ({
                        url: `session/${session}`,
                        method: 'GET',
                    }),

                    transformResponse: (response) => response.data
                }),
            deleteSession:
                builder.mutation({
                    query:({session}) => ({
                        url: `session/${session}`,
                        method: 'DELETE',
                    }),

                    transformResponse: (response) => response.data
                }),
            createSession:
                builder.mutation({
                    query:() => ({
                        url: `session/create`,
                        method: 'GET',
                    }),

                    transformResponse: (response) => response.data
                }),
            updateSession:
                builder.mutation({
                    query:({data = {}}) => ({
                        url: `session/update`,
                        method: 'POST',
                        body: data
                    }),

                    transformResponse: (response) => response.data
                })
    })
})

export const {
    useGetSessionsMutation,
    useDeleteSessionMutation,
    useGetSessionMutation,
    useCreateSessionMutation,
    useUpdateSessionMutation,
} = spacesApi