import {Form} from "react-router-dom";
import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Radio, Toggle} from "../Forms/CheckboxFields";
import {Input, InputFieldGroup, Textarea} from "../Forms/InputField";
import Select from "react-select";
import React, {useEffect} from "react";
import {StepFooter} from "./NewSpace";
import {generateTimeOptions, noticePeriods, units, daysOfWeek} from "./helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {useUpdateSessionMutation} from "../../app/api/spacesApi";


const servicesScheme = yup
    .object({
        type: yup.string().required(),
        open_time: yup.string().optional(),
        close_time: yup.string().optional(),
        notice_unit: yup.string().required('Notice Period Units required'),
        notice: yup.string().required('Notice Period required'),
        duration: yup.string().required('Minimum duration required'),
        duration_unit: yup.string().required('Minimum duration unit required'),
        days: yup.object().when('type', {
            is: (val) => val === 2 || val === 3,
            then: () => yup
                .object()
                .test(
                    'at-least-one-day',
                    'You must select at least one day',
                    (days) => {
                        // Check if at least one day is selected
                        return Object.values(days || {}).some(value => value === true);
                    }
                ),
            otherwise: ()=> yup.object().notRequired(),
        }),
        options: yup.array().of(
            yup.object({
                description: yup.string().required('Description is required'),
                price: yup.number().required('Price is required').positive('Price must be positive'),
                unit_type: yup.string().required('Unit type is required'),
                min_people: yup.number().required('Min people is required').positive('Min people must be positive').typeError('Min people is required'),
                max_people: yup.number().required('Max people is required').positive('Max people must be positive').typeError('Max people is required'),
            })
        ).max(5, 'You can add up to 5 options').min(1, "You must have at least one option"),
    })
    .required()


const ServicesComponent = ({setActiveStep, markStepAsCompleted, session, defaultData = {}}) => {

    const [updateSession, {isLoading}] = useUpdateSessionMutation();

    const timeOptions = generateTimeOptions();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues:  { ...defaultData, type: "2"},
        resolver: yupResolver(servicesScheme),
        mode: 'onBlur'
    })



    const type = useWatch({control, name:`type`});
    const notice_unit = useWatch({control, name:`notice_unit`});
    const duration_unit = useWatch({control, name:`duration_unit`});


    const { fields, append, remove } = useFieldArray({
        control,
        name: "options",
    });

    const addOption = () => {
        if (fields.length < 5) {
            append({ description: "", price: "", unit_type: "", min_people: "", max_people: "", space_size: "" });
        }
    };

    const onSubmit = async (data) => {
        if (!isLoading) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 5,
                    "data": {"services": data}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success("Space Services updated successfully")
                markStepAsCompleted(5)
                setActiveStep(null)
            }

        }
    }

    return (
        <>
            <div className={'new-space__header'}>
                <h1 className={'title'}>Service</h1>
                <p className={"strapline"}>Define the listing service offered for this space.</p>
            </div>
            <div className={'new-space__services'}>

                <Form onSubmit={handleSubmit(onSubmit)} id="servicesForm" className="space__services_form">

                    <fieldset className={'input__fieldset new-space__subsection'}>
                        <p className={'font-bold font-montserrat text-gray-700 mb-4'}>
                            Choose from one of the available service types and then configure your service options and
                            prices.
                        </p>
                        <div className={'serviceRow'}>
                            <Radio id={`type_1`}
                                   name={'type'}
                                   label={"Pay as you go"}
                                   showLabel={true}
                                   value={"2"}
                                   register={register}
                                   errors={errors.type}
                            />

                            <p className={"text-sm text-gray-500"}>
                                All your guests to book when and how they need, based on hourly, daily or weekly rates.
                            </p>
                        </div>

                        <div className={'serviceRow'}>
                            <Radio id={`type_2`}
                                   name={'type'}
                                   label={"Part Time"}
                                   showLabel={true}
                                   value={"3"}
                                   register={register}
                                   errors={errors.type}
                            />

                            <p className={"text-sm text-gray-500"}>
                                Your guest can lease your space part time on specific days based on weekly, monthly or
                                yearly.
                            </p>
                        </div>

                        <div className={'serviceRow'}>
                            <Radio id={`type_3`}
                                   name={'type'}
                                   label={"Short Term"}
                                   showLabel={true}
                                   value={"4"}
                                   register={register}
                                   errors={errors.type}
                            />

                            <p className={"text-sm text-gray-500"}>
                                Your guest can lease your space short term up to 6 months at a time.
                            </p>
                        </div>

                        <div className={'serviceRow'}>
                            <Radio id={`type_4`}
                                   name={'type'}
                                   label={"Long Term"}
                                   showLabel={true}
                                   value={"5"}
                                   register={register}
                                   errors={errors.type}
                            />

                            <p className={"text-sm text-gray-500"}>
                                Your guest can lease your space long term of 6 months and longer.
                            </p>
                        </div>

                    </fieldset>

                    {(type == "2" || type == "3") && (
                        <fieldset className={'input__fieldset new-space__subsection'}>
                            <div className={"new-space__subheader mt-10"}>
                                <h2 className={'new-space__subsection_title !text-lg'}>What days and times are your
                                    space available</h2>
                                <p className={'new-space__subsection_strap'}>Choose the days of the week that your
                                    guests can pick from</p>
                            </div>


                            <div className={'days grid grid-cols-7'}>
                                {daysOfWeek.map((day) => (
                                    <>
                                        <InputFieldGroup className={'flex flex-row gap-2'}>
                                            {day}
                                            <Toggle
                                                key={day}

                                                showLabel={false}
                                                showError={false}
                                                checkedLabel={'Open'}
                                                uncheckedLabel={'Closed'}
                                                id={`${day}`}
                                                name={`days[${day}]`}  // Use a unique name for each checkbox
                                                register={register}
                                                errors={errors[day]}
                                            />


                                        </InputFieldGroup>


                                    </>

                                ))}


                            </div>
                            {errors?.days && (
                                <p className="text-red-500 text-sm mt-3 w-full">{errors?.days.message}</p>
                            )}

                            <InputFieldGroup className={'mt-8'}>
                                <p className={'mr-2 items-center flex text-gray-500'}>From</p>
                                <div className="input__field">
                                    <label className="sr-only input__field_label normal" htmlFor={`open_time`}>Open Time
                                        *</label>
                                    <Controller
                                        control={control}
                                        name={`open_time`}
                                        render={({field: {onChange, onBlur, value, ref}}) => (
                                            <Select options={timeOptions}
                                                    ref={ref}
                                                    id={`open_time`}
                                                    value={timeOptions.find(c => c.value === (value || "07:00"))}
                                                    onChange={val => onChange(val.value)}
                                                    classNames={{
                                                        control: () => `${errors.open_time ? "error" : ""} react-select`,
                                                        option: () => "react-select__option",
                                                    }}
                                            />
                                        )}
                                    />
                                    {errors?.open_time && (
                                        <p className="text-red-500 text-sm mt-1">{errors?.open_time.message}</p>
                                    )}

                                </div>
                                <p className={'ml-2 mr-2 items-center flex text-gray-500'}>to</p>
                                <div className="input__field">
                                    <label className="sr-only input__field_label normal" htmlFor={`close_time`}>Close
                                        Time *</label>
                                    <Controller
                                        control={control}
                                        name={`close_time`}
                                        render={({field: {onChange, onBlur, value, ref}}) => (
                                            <Select options={timeOptions}
                                                    id={`close_time`}
                                                    ref={ref}
                                                    value={timeOptions.find(c => c.value === (value || "17:00"))}
                                                    onChange={val => onChange(val.value)}
                                                    classNames={{
                                                        control: () => `${errors?.close_time ? "error" : ""} react-select`,
                                                        option: () => "react-select__option",
                                                    }}
                                            />
                                        )}
                                    />
                                    {errors?.close_time && (
                                        <p className="text-red-500 text-sm mt-1">{errors?.close_time.message}</p>
                                    )}

                                </div>

                            </InputFieldGroup>
                        </fieldset>
                    )}


                    <fieldset className={"input__fieldset new-space__subsection"}>
                        <div className={"new-space__subheader"}>
                            <h2 className={'new-space__subsection_title !text-lg'}>What notice do you need for a
                                booking</h2>
                            <p className={'new-space__subsection_strap'}>Choose the notice period unit and provide us with a
                                time.</p>

                            <p className={'font-bold text-sm mb-6 mt-6'}>For example: If more than 24 hours use days, if
                                more than 7 days use weeks etc.</p>
                        </div>
                        <InputFieldGroup>
                            <div className="input__field">
                                <label className="input__field_label normal" htmlFor={`notice_unit`}>Notice Period
                                    Measurement *</label>
                                <Controller
                                    control={control}
                                    name={`notice_unit`}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <Select options={units}
                                                id={`notice_unit`}
                                                ref={ref}
                                                value={units.find(c => c.value === (value || "17:00"))}
                                                onChange={val => onChange(val.value)}
                                                classNames={{
                                                    control: () => `${errors?.notice_unit ? "error" : ""} react-select`,
                                                    option: () => "react-select__option",
                                                }}
                                        />
                                    )}
                                />
                                {errors?.notice_unit && (
                                    <p className="text-red-500 text-sm mt-1">{errors?.notice_unit.message}</p>
                                )}

                            </div>
                            <div className="input__field">
                                <label className="input__field_label normal" htmlFor={`notice`}>Notice Period *</label>
                                <Controller
                                    control={control}
                                    name={`notice`}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <Select options={noticePeriods[notice_unit]}
                                                id={`notice`}
                                                ref={ref}
                                                value={noticePeriods[notice_unit]?.find(c => c.value === (value))}
                                                onChange={val => onChange(val.value)}
                                                classNames={{
                                                    control: () => `${errors?.notice ? "error" : ""} react-select`,
                                                    option: () => "react-select__option",
                                                }}
                                        />
                                    )}
                                />
                                {errors?.notice && (
                                    <p className="text-red-500 text-sm mt-1">{errors?.notice.message}</p>
                                )}

                            </div>
                        </InputFieldGroup>
                    </fieldset>


                     <fieldset className={"input__fieldset new-space__subsection"}>
                          <div className={"new-space__subheader mt-10"}>
                                <h2 className={'new-space__subsection_title !text-lg'}>What minimum duration of a booking</h2>
                                <p className={'new-space__subsection_strap'}>Choose the minimum duration period unit and provide
                                    us with a booking minimum.</p>

                                <p className={'font-bold text-sm mb-6 mt-6'}>For example: If more than 24 hours use days, if
                                    more than 7 days use weeks etc.</p>
                          </div>
                        <InputFieldGroup>
                            <div className="input__field">
                                <label className="input__field_label normal" htmlFor={`duration_unit`}>Minimum Duration
                                    Measurement *</label>
                                <Controller
                                    control={control}
                                    name={`duration_unit`}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <Select options={units}
                                                id={`duration_unit`}
                                                ref={ref}
                                                value={units.find(c => c.value === (value || "17:00"))}
                                                onChange={val => onChange(val.value)}
                                                classNames={{
                                                    control: () => `${errors?.duration_unit ? "error" : ""} react-select`,
                                                    option: () => "react-select__option",
                                                }}
                                        />
                                    )}
                                />
                                {errors?.duration_unit && (
                                    <p className="text-red-500 text-sm mt-1">{errors?.duration_unit.message}</p>
                                )}

                            </div>
                            <div className="input__field">
                                <label className="input__field_label normal" htmlFor={`duration`}>Minimum Duration
                                    Period *</label>
                                <Controller
                                    control={control}
                                    name={`duration`}
                                    render={({field: {onChange, onBlur, value, ref}}) => (
                                        <Select options={noticePeriods[duration_unit]}
                                                id={`duration`}
                                                ref={ref}
                                                value={noticePeriods[duration_unit]?.find(c => c.value === (value))}
                                                onChange={val => onChange(val.value)}
                                                classNames={{
                                                    control: () => `${errors?.duration ? "error" : ""} react-select`,
                                                    option: () => "react-select__option",
                                                }}
                                        />
                                    )}
                                />
                                {errors?.duration && (
                                    <p className="text-red-500 text-sm mt-1">{errors?.duration.message}</p>
                                )}

                            </div>
                        </InputFieldGroup>
                     </fieldset>

                    <div className={"new-space__subheader mt-10"}>
                        <h2 className={'new-space__subsection_title'}>Options</h2>
                        <p className={'new-space__subsection_strap'}>We need to add the space options available for the
                            service.</p>


                        {fields.map((item, index) => (
                            <fieldset key={item.id} className="border p-4 mb-4 relative">
                                <legend>Option {index + 1}</legend>
                                <div className={'flex flex-row gap-4'}>
                                    <Textarea
                                        showLabel={true}
                                        register={register}
                                        label={"Describe your option"}
                                        id={`options[${index}].description`}
                                        required={true}
                                        errors={errors}
                                    />
                                    <div className={'flex flex-col gap-5 flex-1'}>
                                        <Input
                                            showLabel={true}
                                            register={register}
                                            type={"number"}
                                            label={"Price"}
                                            id={`options[${index}].price`}
                                            required={true}
                                            errors={errors}
                                            suffix={'£'}
                                        />

                                        <Input
                                            showLabel={true}
                                            register={register}
                                            type={"number"}
                                            label={"Minimum Number of People"}
                                            id={`options[${index}].min_people`}
                                            required={true}
                                            errors={errors}
                                        />

                                    </div>

                                    <div className={'flex flex-col gap-5 flex-1'}>

                                        <div className={"input__field"}>
                                            <label className="input__field_label normal"
                                                   htmlFor={`options[${index}].unit_type`}>Price Unit Type*</label>
                                            <Controller
                                                control={control}
                                                name={`options[${index}].unit_type`}
                                                render={({field: {onChange, onBlur, value, ref}}) => (
                                                    <Select options={units}
                                                            id={`options[${index}].unit_type`}
                                                            ref={ref}
                                                            value={units.find(c => c.value === (value))}
                                                            onChange={val => onChange(val.value)}
                                                            classNames={{
                                                                control: () => `${errors?.options?.[index]?.unit_type ? "error" : ""} react-select`,
                                                                option: () => "react-select__option",
                                                            }}
                                                    />
                                                )}
                                            />
                                            {errors?.options?.[index]?.unit_type && (
                                                <p className="text-red-500 text-sm">{errors?.options?.[index]?.unit_type.message}</p>
                                            )}

                                        </div>
                                        <Input
                                            showLabel={true}
                                            register={register}
                                            type={"number"}
                                            label={"Maximum Number of People"}
                                            id={`options[${index}].max_people`}
                                            required={true}
                                            errors={errors}
                                        />
                                    </div>
                                </div>

                                <button type="button" onClick={() => remove(index)}
                                        className="text-red-500 absolute top-[-40px] bg-white p-4 right-5">
                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" className={'text-red-500'}/>
                                </button>
                            </fieldset>
                        ))}

                        {errors?.options && (
                            <p className="text-red-500 text-sm mt-1">{errors?.options.message}</p>
                        )}

                        <button type="button" className={'btn__auth_back small'} onClick={addOption}
                                disabled={fields.length >= 5}>
                            <FontAwesomeIcon icon="fa-solid fa-plus" className={'text-green-500 mr-2'}/> Add Option
                        </button>
                    </div>

                    <StepFooter setActiveStep={setActiveStep}/>
                </Form>
            </div>
        </>
    )
}
export default ServicesComponent