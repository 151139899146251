
import './styles.css'
import {useDispatch, useSelector} from "react-redux";
import TwoFactorForm from "./TwoFactorForm";
import {logout} from "../../app/features/auth/authSlice";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";


export const TwoFactor = ({
                              formError = "" ,
                              setFormError = () => {},
                              backAction = null, children}
) => {
    const dispatch = useDispatch()

    if (!backAction) {
        backAction = () => {
            dispatch(logout())
        }
    }

    return (
        <>
            <Helmet>
                <title>Cohesii | Confirm your identity</title>
            </Helmet>

            <h1 className={'auth__title'}>Congratulations</h1>
            <p className={'auth__strapline'}>Just one last step, please enter the pin from your email to confirm you are who you say.</p>

            {formError && <p className="rounded-md text-red-500 text-md bg-red-50 border-red-100 border p-4 mt-6 mb-3">{formError}</p>}


            <TwoFactorForm backAction={backAction} setFormError={setFormError} />

            {children}


        </>
    )
}

const TwoFactorView = () => {

    const needsTwoFactor = useSelector((state) => state.auth.needsTwoFactor);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const backAction = () => {
        dispatch(logout())
        navigate('/auth/login')
    }

    return (
        <>
            <section className={'twofactor-container'}>
                <div className={'twofactor'}>
                    {needsTwoFactor && <TwoFactor backAction={backAction}/>}
                </div>
            </section>

        </>
    )
}

export default TwoFactorView