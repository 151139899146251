import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const getErrorById = (errors, id) => {
    // Split the id by dot notation and brackets (e.g., 'options[0].unit_type')
    const pathParts = id.split(/[\.\[\]]/).filter(Boolean); // Split by dot or brackets, remove empty strings

    // Traverse the errors object based on the split path
    return pathParts.reduce((acc, part) => acc ? acc[part] : undefined, errors);
};

export const InputField = ({inline = false, children}) => {
    return (
        <div className={`input__field ${inline ? 'inline' : ''}`}>
            {children}
        </div>
    )
}
export const InputFieldGroup = ({className, children}) => {
    return (
        <div className={`input__fieldset group ${className}`}>
            {children}
        </div>
    )
}


export const Input = ({
                          label = "",
                          id = "",
                          type="text",
                          autocomplete = false,
                          required = false,
                          showLabel = true,
                          disabled = false,
                          inline = false,
                          prefix = null,
                          suffix = null,
                          icon = null,
                          register,
                          errors


                      }) => {

    const error = getErrorById(errors, id);


    return (
        <>
            <InputField inline={inline}>
                {showLabel &&
                    <label className="input__field_label normal" htmlFor={id}>
                        {label} {required && <span className="required">*</span>}
                    </label>
                }

                {/* Wrapper for input with prefix/suffix */}
                <div className={`relative flex items-center ${prefix || suffix ? 'input-with-addon' : ''}`}>
                    {/* Render prefix if exists */}
                    {prefix && (
                        <span className="input__prefix">
                            {prefix}
                        </span>
                    )}
                    <div className={'relative flex-1'}>
                        {icon && <FontAwesomeIcon icon={icon} className={'absolute left-4 top-1/2 -translate-y-1/2'}/> }
                        <input
                            type={type}
                            {...register(id)}
                            disabled={disabled}
                            placeholder={`${label} ${required ? "*" : ""}`}
                            id={id}
                            {...(autocomplete && {autoComplete: autocomplete})} // Conditionally add autoComplete
                            className={`input__field_input ${icon ? '!pl-[40px]' : ''} ${error ? "error" : ""} ${prefix ? "with-prefix" : ""} ${suffix ? "with-suffix" : ""}`}
                        />
                    </div>

                    {/* Render suffix if exists */}
                    {suffix && (
                        <span className="input__suffix">
                            {suffix}
                        </span>
                    )}
                </div>
                {error && (
                    <p className="text-red-500 dark:text-red-200 text-sm mt-1 mb-2">{error.message}</p>
                )}

            </InputField>


        </>
    );
}


export const Textarea = ({label = "", id="", autocomplete = false, required=false, showLabel=true, register, errors}) => {

    const error = getErrorById(errors, id);

    return (
        <InputField>
            {showLabel &&
                <label className="input__field_label normal" htmlFor={id}>
                    {label} {required && <span className="required">*</span>}
                </label>
            }
            <textarea
                {...register(id)}
                placeholder={`${label} ${required ? "*" : ""}`}
                id={id}
                {...(autocomplete && {autoComplete: autocomplete})} // Conditionally add autoComplete
                className={`input__field_textarea ${error ? "error" : ""}`}
            />
            {error && (
                <p className="text-red-500 text-sm mt-1">{error.message}</p>
            )}
        </InputField>
    )
}
