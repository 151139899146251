import {Helmet} from "react-helmet-async";
import SaasHeader from "../../../Components/SaasHeader";
import SaasBox from "../../../Components/SaasBox";
import useRoleRedirect from "../../../hooks/useRoleRedirect";
import './styles.css'
import Recommendation from "./Recommendation";

const Match = () => {
    useRoleRedirect('Licensee')

    return (
        <>
            <Helmet>
                <title>Cohesii | Match a space</title>
            </Helmet>
            <SaasHeader title={"Match a space"} >

            </SaasHeader>
            <Recommendation />
        </>
    )
}

export default Match