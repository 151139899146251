import {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {logout, setUser} from "../app/features/auth/authSlice";
import {useGetMeMutation} from "../app/api/userApi";

const useFetchUser = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getMe] = useGetMeMutation();

    const user = useSelector((state) => state.auth.user);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = useSelector((state) => state.auth.token);

    const fetchUser = async () => {
        try {
            if (!user?.id && token) {
                const { data, error } = await getMe({});
                if (error) {
                    throw new Error("Failed to fetch user");
                }
                dispatch(setUser(data));
            }
        } catch (error) {
            localStorage.setItem("redirectAfterLogin", window.location.pathname);
            dispatch(logout());
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {


        if (!user?.id && token) {
            fetchUser();
        } else {
            setLoading(false);
        }
    }, [token, user?.id]);

    useEffect(() => {
        if (loading) return;

        const currentPath = window.location.pathname;
        const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");

        if (isAuthenticated && user?.id) {
            if (currentPath.startsWith("/auth")) {
                if (redirectAfterLogin) {
                    localStorage.removeItem("redirectAfterLogin");
                    navigate(redirectAfterLogin);
                } else {
                    navigate("/");
                }
            }
        } else {
            if (!isAuthenticated && !currentPath.startsWith("/auth")) {
                navigate("/auth");
            }
        }
    }, [isAuthenticated, user?.id, navigate, loading]);

    return loading;
};

export default useFetchUser;