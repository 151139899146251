import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

console.log(process.env.REACT_APP_AI_API_URL)

export const aiApi = createApi({
    reducerPath: 'aiApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_AI_API_URL
    }),
    endpoints: (builder) => ({
        calculate:
            builder.mutation({
                query:({calculate}) => ({
                    url: `space/price/calculate`,
                    method: 'POST',
                    body: calculate
                }),
                transformResponse: (response) => response
            }),
        query:
            builder.mutation({
                query:({query}) => ({
                    url: `space/search`,
                    method: 'POST',
                    body: {
                        "query": query
                    }

                }),
                transformResponse: (response) => response
            }),

    })
})

export const {
    useCalculateMutation,
    useQueryMutation,
} = aiApi