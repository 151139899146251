// Separator.jsx

import React from "react";
import "./styles.css"; // Import external CSS

const Separator = ({ text }) => {
    return (
        <div className="separator">
            <span className="circle"></span>
            {text && <span className="separator-text">{text}</span>}
            <span className="circle"></span>
        </div>
    );
};

export default Separator;