import {Form, Link} from "react-router-dom";
import {useEffect, useState} from "react";
import { validateRequired} from "../../Components/Forms/validation";
import PasswordStrengthBar from "react-password-strength-bar";

const StepThree = ({registerError, setRegisterError, setRegisterData, setRegister, setStep}) => {

    const [score, setScore] = useState(0);
    const [formState, setFormState] = useState({
        password: '',
        confirmPassword: '',

        errors: {
            password: '',
            confirmPassword: '',
        },
    });

    useEffect(() => {
        const errors = validateForm()

        // Update the form state, including the specific field's error
        setFormState((prevState) => ({
            ...prevState,
            errors: errors
        }));

    }, [formState.password, formState.confirmPassword]);

    // Function to handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        const errors = validateForm()

        // Update the form state, including the specific field's error
        setFormState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // Validation Logic
    const validateForm = () => {
        const newErrors = {};
        const password = formState.password.trim().normalize();
        const confirmPassword = formState.confirmPassword.trim().normalize();


        if (!validateRequired(password) || score <= 2) newErrors.password = "Password is required with 8 characters.";
        if (!validateRequired(confirmPassword)) newErrors.confirmPassword = "Confirm password is required.";
        if (!(password === confirmPassword)) newErrors.confirmPassword = "Confirm password must match.";

        return newErrors;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setFormState((prevState) => ({
                ...prevState,
                errors: validationErrors,
            }));
        } else {
            // No errors, submit data
            setRegisterData("password", formState.password);
            setRegisterData("confirmPassword", formState.confirmPassword);
            setRegister(true)
        }
    };

    return (
        <>
            <h1 className="auth__title">Lets be secure</h1>
            <p className={'auth__strapline'}>Set your password to be able to access.</p>

            {registerError && (
                <p className="rounded-md text-red-500 text-md bg-red-50 border-red-100 border p-4 mt-6 mb-3">
                    {registerError}
                </p>
            )}


            <Form onSubmit={onSubmit} id="registerFormStepThree" className="register-form__step_three mt-6">
                <fieldset className="input__fieldset">
                    <div className="input__field email">
                        <label className="input__field_label large" htmlFor="password">Password *</label>
                        <input
                            className={`input__field_input ${formState.errors.password ? "error" : ""}`}
                            type="password"
                            name="password"
                            value={formState.password}
                            onChange={handleChange}
                            placeholder="Enter your password *"
                            id="password"
                        />
                        {formState.errors.password && (
                            <p className="text-red-500 text-sm mt-1">{formState.errors.password}</p>
                        )}
                    </div>

                    <PasswordStrengthBar password={formState.password} minLength={8}
                                         onChangeScore={(score) => setScore(score)}/>

                    <div className="input__field email">
                        <label className="input__field_label large" htmlFor="confirmPassword">Confirm Password *</label>
                        <input
                            className={`input__field_input ${formState.errors.confirmPassword ? "error" : ""}`}
                            type="password"
                            name="confirmPassword"
                            value={formState.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm your password *"
                            id="confirmPassword"
                        />
                        {formState.errors.confirmPassword && (
                            <p className="text-red-500 text-sm mt-1">{formState.errors.confirmPassword}</p>
                        )}
                    </div>

                </fieldset>

                <div className={'flex flex-row gap-3'}>
                    <button type="button" onClick={() => {setStep(2); setRegisterError('');}} className="btn__auth_back ">
                        Back
                    </button>
                    <button type="submit" className="btn__auth_primary">
                        Register
                    </button>
                </div>
            </Form>

            <p className="auth-footer_links">
                Already have an account?{' '}
                <Link to="/auth/login" title="Cohesii Login">
                    Login
                </Link>
            </p>
        </>
    );
};

export default StepThree