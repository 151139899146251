import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import './styles.css'
const GridItem = ({id,space}) => {

    const {images, name, description, location} = space;

    const isBase64 = (str) => {
        // Check if the string starts with base64 prefix
        return str.startsWith('data:image/');
    };

    const cleanUrl = (image) => {
        let url =  image.replace('//', '/');
        if (!url.match(/\.\w+$/)) {
            // If no extension, append .jpg
            return `${url}.jpg`;
        }

        return url
    }

    return (
        <div className={'flex flex-col flex-1'}>
            <div className={'spaces-list__item group'}>

                <Link to={`/spaces/${id}`} title={name} className={'group-hover:elevation-1 flex-1'}>
                    {images?.length > 0 && (
                        <img title={name} src={cleanUrl(images[0])}
                             className={'w-full min-h-auto max-h-[200px] object-cover object-center'}
                             loading={"lazy"}
                             alt={name}/>
                    )}
                    <div className={'spaces-list__item_content'}>
                        <div className={'title-container'}>
                            <div className={'title'}>{name}</div>
                            <p className={'flex flex-row items-center'}>
                                <FontAwesomeIcon icon={'fa-solid fa-location-dot'} className={'size-4 text-blue-950'}/>
                                <span
                                    className={'text-blue-950 font-lora font-medium text-sm ml-2 text-oblique'}>{location ?? ""}</span>
                            </p>
                        </div>

                        <div className={'content line-clamp-4'} dangerouslySetInnerHTML={{__html: description}}/>

                    </div>
                </Link>
            </div>
        </div>
    )

}

export default GridItem