import Separator from "../../Components/Seperator/Seperator";
import './styles.css'
import SocialConnect from "./SocialConnect";
import LoginForm from "./LoginForm";
import {useDispatch, useSelector} from "react-redux";

import {TwoFactor} from "../TwoFactor/TwoFactorView";
import {useState} from "react";
import {logout} from "../../app/features/auth/authSlice";
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet-async";


export const LoginContent = ({loginError, setLoginError}) => {
    return (
        <>
            <h1 className={'auth__title'}>Log in</h1>
            <p className={'auth__strapline'}>Welcome back! Please enter your details.</p>

            {loginError && <p className="rounded-md text-red-500 text-md bg-red-50 border-red-100 border p-4 mt-6 mb-3">{loginError}</p>}

            <SocialConnect/>
            <Separator text="or"/>
            <LoginForm setLoginError={setLoginError}/>

            <p className={'auth-footer_links'}>
                Dont have an  account?
                <Link to={'/auth/register'} title={'Cohesii Register'}>
                    Sign Up
                </Link>
            </p>
        </>
    )
}

const LoginView = () => {

    const dispatch = useDispatch()

    const location = useLocation();

    const [formError, setFormError] = useState(location?.state?.formError ?? null) ;

    const needsTwoFactor = useSelector((state) => state.auth.needsTwoFactor);

    const backAction = () => {
        dispatch(logout())
    }

    return (
        <>
            <Helmet>
                <title>Cohesii | Login to your portal</title>
            </Helmet>
            <section className={'login-container'}>
                <div className={'login'}>
                    {!needsTwoFactor && <LoginContent loginError={formError} setLoginError={setFormError} /> }


                    {needsTwoFactor && <TwoFactor backAction={backAction} formError={formError} setFormError={setFormError} />}
                </div>
            </section>

        </>
    )
}

export default LoginView