export function* range(start, stop, step = 1) {
    if (stop == null) {
        // one param defined
        stop = start;
        start = 0;
    }

    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        yield {'label': i, 'value': i}
    }
}

export const units = [
    {'value': 'hours', 'label': 'Hours'},
    {'value': 'days', 'label': 'Days'},
    {'value': 'weeks', 'label': 'Weeks'},
    {'value': 'months', 'label': 'Months'},
];

export const noticePeriods = {
    'hours': [...range(0,25)],
    'days' : [...range(0,8)],
    'weeks' : [...range(0,5)],
    'months' : [...range(0,13)]
}

export const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday',
];

export const generateTimeOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
        const hour = i.toString().padStart(2, '0'); // Ensure two digits for hours
        options.push({ value: `${hour}:00`, label: `${hour}:00` });
        options.push({ value: `${hour}:30`, label: `${hour}:30` });
    }
    return options;
};
