import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

const ImageDragDrop = ({classes ='max-w-xl', isMultiple=true, handleDrop = () => {}, handleBrowse = () => {}}) => {


    return (
        <div
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            className={`${classes}`}
        >
            <label
                className="h-full flex justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                        <span className="flex items-center space-x-2">
                            <FontAwesomeIcon icon="fa-solid fa-upload"/>
                            <span className="font-medium text-gray-600">
                                Drop files to Attach, or
                                <span className="text-blue-600 underline pl-2">browse</span>
                            </span>
                        </span>
                <input
                    type="file"
                    name="file_upload"
                    className="hidden"
                    accept="image/*"
                    multiple={isMultiple}
                    onChange={handleBrowse}
                />
            </label>
        </div>
    )
}

export default ImageDragDrop