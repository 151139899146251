import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useRoleRedirect = (allowRole) => {
    const navigate = useNavigate();

    // Fetch the user's role from the Redux store
    const userRoles = useSelector((state) => state.auth.user?.roles);

    useEffect(() => {
        if (userRoles) {
            // Check if any of the user's roles match the allowed roles
            const isAllowed = Array.isArray(allowRole)
                ? userRoles.some((role) => allowRole.includes(role)) // Check if any role matches
                : userRoles.includes(allowRole); // Check if the single role matches

            if (!isAllowed) {
                navigate("/"); // Redirect to unauthorized page if no match
            }
        }
    }, [userRoles]);

};

export default useRoleRedirect;