import {Form} from "react-router-dom";
import * as yup from "yup";
import {set, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Input} from "../../../Components/Forms/InputField";


const calcSchema = yup
    .object({
        size: yup.number().positive("Space size must be a positive number").typeError("Space size must be a positive number").required(),
        people: yup.number().positive("Maximum people must be a positive number").typeError("Maximum people must be a positive number").required(),
        margin: yup.number().positive("Profit margin must be a positive number").typeError("Profit margin must be a positive number"),
        min_hours: yup.number().positive("Minimum hours must be a positive number").typeError("Minimum hours  must be a positive number"),
    })
    .required()

const CalculatorForm = ({callback = (data) => {}}) => {

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            "size": 350,
            "people": 1,
            "margin": 15,
            "min_hours": 1,
        },
        resolver: yupResolver(calcSchema),
    })

    const submit = async (data) => {
        callback(data)
    }

    return (
        <Form onSubmit={handleSubmit(submit)} id="locationForm" className="space__calculator_form">
            <fieldset className="input__fieldset">
                <Input
                    register={register}
                    label={"Size (sqft)"}
                    id={"size"}
                    type={"number"}
                    required={true}
                    errors={errors}
                />
                <Input
                    register={register}
                    label={"Number of people"}
                    id={"people"}
                    type={"number"}
                    required={true}
                    errors={errors}
                />
                <Input
                    register={register}
                    label={"Profit Margin"}
                    id={"margin"}
                    type={"number"}
                    required={true}
                    errors={errors}
                />
                <Input
                    register={register}
                    label={"Minimum hours"}
                    id={"min_hours"}
                    type={"number"}
                    required={true}
                    errors={errors}
                />
            </fieldset>

            <div className={'new-space__footer'}>
                <button className={'btn__auth_back small'} >
                    Reset
                </button>
                <button type={"submit"} className={'btn__auth_primary small'}>
                    Next
                </button>
            </div>
        </Form>
    )
}

export default CalculatorForm