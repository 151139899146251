import 'react-toastify/dist/ReactToastify.css';
import {Outlet} from "react-router-dom";

import Logo from './assets/cohesii-white.svg'
import useFetchUser from "./hooks/useFetchUser";
import {ToastContainer} from "react-toastify";
import React from "react";

function App() {

    const loading = useFetchUser()

    return (
        <div className="auth overflow-hidden">
            <header>
                <div className={'container py-4'}>
                    <picture>
                        <img src={Logo} title={'Cohesii'} alt={'Cohesii Orange gradient logo'}/>
                    </picture>
                </div>
            </header>
            {!loading &&
                <main className={'auth__container'}>
                    <Outlet/>

                    <section className={"auth__right"}>

                    </section>
                </main>
            }

            {loading && <>Loading ...</>}

            <footer className={'py-6'}>
                <p className={'container text-lg text-white'}>Copyright Cohesii 2024 </p>
            </footer>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick

            />
        </div>
    );
}

export default App;
