import {Helmet} from "react-helmet-async";
import SaasHeader from "../../../../Components/SaasHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SaasBox from "../../../../Components/SaasBox";
import {Link} from "react-router-dom";
import DetailsForm from "../../DetailsForm";
import {useDeleteBankAccountMutation, useGetPaymentDetailsMutation} from "../../../../app/api/userApi";
import {useEffect, useState} from "react";
import {useModal} from "../../../../Components/Modal/modal-context";
import AddBankAccount from "./AddBankAccount";
import useRoleRedirect from "../../../../hooks/useRoleRedirect";
import Confirmation from "../../../../Components/Modal/ConfirmationModal";
import {toast} from "react-toastify";
import * as yup from "yup";
import Loading from "../../../../Components/Loading";


const Payouts = () =>  {

    useRoleRedirect('Space Owner')

    const { setModal, unSetModal } = useModal()

    const [details, setDetails] = useState({});

    const [getPaymentDetails, {isLoading}] = useGetPaymentDetailsMutation()
    const [deleteBankAccount, {isLoading:isLoadingDelete}] = useDeleteBankAccountMutation()

    const getDetails = async () => {
        if (!isLoading) {
            const response =  await getPaymentDetails({type:"payout"})
            if (response?.data) {
                setDetails(response?.data)
            }

            unSetModal()

        }
    }

    useEffect(() => {
        getDetails()
    }, []);

    const deleteBankCard = async (id) => {
        if (!isLoadingDelete)
        {
            const response = await deleteBankAccount({id: id})
            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success(`Card deleted successfully`)
                await getDetails()
                return true
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Cohesii | Settings | Billing Payouts</title>
            </Helmet>
            <SaasHeader title={"Billing Payouts"} add={false} >
                <div className={'back-link'} slot={"back"}>
                    <Link to={'/account/overview'}>
                        <FontAwesomeIcon icon="fa-solid fa-arrow-left" className={'mr-2'} />
                        Back to settings
                    </Link>
                </div>
            </SaasHeader>

            <div className={'bg-yellow-50 border-yellow-300 border-[1px] p-3 text-gray-800 mb-5 mt-5 text-sm'}>
                <FontAwesomeIcon icon="fa-solid fa-circle-info" className={'mr-2 text-yellow-500'} /> The payment is powered by Stripe and <strong>we do not store sensitive payment information on our servers</strong>.
            </div>

             <Loading isLoading={isLoading} />

            {!isLoading &&  <SaasBox className={'mt-4'}>
                <h3 className={"section__title"}>Personal details</h3>
                <p className={"section__strap"}>Enter your personal details relating to your payout method.</p>

                <DetailsForm type={"payout"}
                                            defaults={details} id={details?.id}
                                            additional_schema={
                                                {

                                                    dob_day: yup.number()
                                                        .required('Day is required')
                                                        .min(1, 'Day must be at least 1')
                                                        .max(31, 'Day must be at most 31')
                                                        .typeError('Day must be a number'),
                                                    dob_month: yup.number()
                                                        .required('Month is required')
                                                        .min(1, 'Month must be at least 1')
                                                        .max(12, 'Month must be at most 12')
                                                        .typeError('Month must be a number'),
                                                    dob_year: yup.number()
                                                        .required('Year is required')
                                                        .min(1900, 'Year must be at least 1900')
                                                        .max(new Date().getFullYear(),
                                                            'Year must be at most current year'
                                                        )
                                                        .typeError('Year must be a number'),
                                                }
                                            }
                />

            </SaasBox>
            }
            {(details?.id && !isLoading) && (
                <SaasBox className={'mt-4'}>
                    <div className={
                        'flex flex-row'
                    }>
                        <h3 className={"section__title flex-1"}>Attached Banks</h3>

                        <button type={"button"}
                                className={'btn__auth_primary small !mt-0'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setModal(<AddBankAccount country={details?.country} name={details?.name} callback={getDetails} />)
                                }}>
                                Link new bank
                        </button>
                    </div>
                    <div className={'accounts mt-4'}>
                        {details?.banks?.map((bank, index) => (
                            <div key={bank.fingerprint}
                                 className={'flex flex-row gap-4 py-4 border-b-[1px] first-of-type:border-t-[1px] border-gray-100'}>
                                <span className={'bg-gray-100 rounded-lg p-2 w-10 items-center justify-center flex'}>
                                    <FontAwesomeIcon icon="fa-solid fa-building-columns" className={'text-gray-800'}/>
                                </span>
                                <div className={'flex flex-col  flex-auto'}>
                                    <p className={'font-montserrat font-bold text-gray-800 dark:text-gray-200'}>
                                        {bank.bank_name}
                                    </p>
                                    <p className={'text-sm ext-gray-800 dark:text-gray-200 flex flex-row gap-2'}>
                                        <span>{bank.routing_number}</span> <span>•••• {bank.last4}</span>
                                    </p>
                                </div>

                                <button type={"button"}
                                        className={'bg-red-500 hover:bg-red-400 text-white p-4 rounded-lg flex items-center justify-center flex-0 h-4 w-4'}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModal(<Confirmation itemType={'Bank card'} deleteAction={() => { deleteBankCard(bank.local_id) }} />  )
                                        }}>
                                    <FontAwesomeIcon icon="fa-solid fa-trash"/>
                                </button>


                            </div>
                        ))}
                    </div>
                </SaasBox>
            )}
        </>
    )

}


export default Payouts