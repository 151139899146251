import React from "react";
import * as yup from "yup";
import {Checkbox, Radio} from "../Forms/CheckboxFields";
import {Form} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import {useUpdateSessionMutation} from "../../app/api/spacesApi";
import {StepFooter} from "./NewSpace";

const cancelationSchema = yup
    .object({
        conversations: yup.bool() // use bool instead of boolean
            .oneOf([true], "You must agree to conversations policy"),
        payments: yup.bool() // use bool instead of boolean
            .oneOf([true], "You must agree to payment policy"),
        booking: yup.bool() // use bool instead of boolean
            .oneOf([true], "You must agree to booking and cancellation policy"),
        legal:yup.bool() // use bool instead of boolean
            .oneOf([true], "You must agree to legal policy"),
    })
    .required()

const TermsComponent = ({setActiveStep, markStepAsCompleted, session, defaultData = {}}) => {

    const [updateSession, {isLoading}] = useUpdateSessionMutation();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues:  { ...defaultData, type: "2"},
        resolver: yupResolver(cancelationSchema),
        mode: 'onBlur'
    })

    const onSubmit = async (data) => {
        if (!isLoading) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 7,
                    "data": {"terms": data}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success("Space terms updated successfully")
                markStepAsCompleted(5)
                setActiveStep(null)
            }

        }
    }

    return (
        <>
            <div className={'new-space__header'}>
                <h1 className={'title'}>Please review the following Cohesii's policies</h1>
                <p className={"strapline"}>You must agree and understand the following requirements as a Cohesii space owner.</p>
            </div>
            <div className={'new-space__terms'}>
                <Form onSubmit={handleSubmit(onSubmit)} id="agreementForm" className="space__agreement_form">
                    <fieldset className={'input__fieldset new-space__subsection'}>
                        <div className={'serviceRow'}>
                            <Checkbox id={`conversations`}
                                      name={'conversations'}
                                      label={"Keep conversations to Cohesii platform"}
                                      showLabel={true}
                                      value={true}
                                      register={register}
                                      errors={errors.conversations}
                            />

                            <p className={"text-sm text-gray-500"}>
                                You agree to keep all conversations with guests on the platform so everyone knows what
                                was agreed to.
                            </p>

                            {errors?.conversations && (
                                <p className="text-red-500 text-sm mt-3 w-full">{errors?.conversations.message}</p>
                            )}
                        </div>
                        <div className={'serviceRow'}>
                            <Checkbox id={`payments`}
                                      name={'payments'}
                                      label={"All payments must be via Cohesii platform"}
                                      showLabel={true}
                                      value={true}
                                      register={register}
                                      errors={errors.payments}
                            />

                            <p className={"text-sm text-gray-500"}>
                                You agree that your guest will only use Cohesii for payments and honour our service
                                fees. You will not offer guests
                                alternative payment methods, before or after first use. All payouts to you will be made
                                by direct deposit to your designated payment method.
                            </p>

                            {errors?.payments && (
                                <p className="text-red-500 text-sm mt-3 w-full">{errors?.payments.message}</p>
                            )}
                        </div>
                        <div className={'serviceRow'}>
                            <Checkbox id={`booking`}
                                      name={'booking'}
                                      label={"You must follow through with all bookings and cancellations"}
                                      showLabel={true}
                                      value={true}
                                      register={register}
                                      errors={errors.booking}
                            />

                            <p className={"text-sm text-gray-500"}>
                                You agree that you will honour all bookings through Cohesii, and if not possible honour
                                the agreed cancellation policy. You may have no personal contract that conflicts with
                                Cohesii policy.
                            </p>

                            {errors?.booking && (
                                <p className="text-red-500 text-sm mt-3 w-full">{errors?.booking.message}</p>
                            )}
                        </div>
                        <div className={'serviceRow'}>
                            <Checkbox id={`legal`}
                                      name={'legal'}
                                      label={"You must make sure your space complies with all legal requirements"}
                                      showLabel={true}
                                      value={true}
                                      register={register}
                                      errors={errors.legal}
                            />

                            <p className={"text-sm text-gray-500"}>
                                You agree that your space is a commercial space and follows all local legal requirements, including
                                health and safety, fire safety and insurance.
                            </p>

                            {errors?.legal && (
                                <p className="text-red-500 text-sm mt-3 w-full">{errors?.legal.message}</p>
                            )}
                        </div>
                    </fieldset>
                    <StepFooter setActiveStep={setActiveStep}  />
                </Form>
            </div>
        </>
    )

}

export default TermsComponent;