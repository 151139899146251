import RegisterImage from './registerRole.png'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const StepOne = ({currentRole, registerError, setRegisterError, setRegisterData,  setStep}) => {


    console.log(currentRole)

    const update = (value) => {
        setRegisterData('role', value)
        setStep(2)
        setRegisterError('')
    }

    return (
        <>
            <h1 className={'auth__title'}>Lets Get started</h1>
            <p className={'auth__strapline'}>What are you trying to do today? .</p>

            {registerError &&
                <p className="rounded-md text-red-500 text-md bg-red-50 border-red-100 border p-4 mt-6 mb-3">{registerError}</p>}

            <picture>
                <img src={RegisterImage} className={'step-one-image'} alt={'Bunch of people around a desk'}/>
            </picture>

            <div className={'socials buttons flex flex-col  mt-6'}>
                <button onClick={() => update( 'Licensee')} type={"button"} className="socials__link">
                    {(currentRole === 'Licensee') && <FontAwesomeIcon icon="fa-solid fa-check" className={'rounded-full p-1  w-3 h-3 flex items-center bg-green-700 text-white mr-2'} />}
                    Looking to find a space
                </button>

                <button onClick={() =>  update('Space Owner')} type={"button"} className="socials__link">
                    {currentRole === 'Space Owner' && <FontAwesomeIcon icon="fa-solid fa-check" className={'rounded-full w-3 h-3 p-1 flex items-center bg-green-700 text-white mr-2'} />}
                    I have a space to share
                </button>
            </div>


            <p className={'auth-footer_links'}>
                Already have an account? ?
                <Link to={'/auth/login'} title={'Cohesii Login'}>
                    Login
                </Link>
            </p>


        </>
    )
}