import Image1 from './demo/user-14-eba44d22.png'
import Image2 from './demo/user-15-73164407.png'

const demoData = [
    {
        'name': "Timothy Smith",
        'image':Image1,
        'when': "1 hour ago",
        'status': 'Pending',
        'review': "Very clean office hot desk, and reasonable for the size and available"
    },
    {
        'name': "Nancy Martino",
        'image':Image2,
        'when': "5 hour ago",
        'status': 'Public',
        'review': "Sizeable meeting room exactly what i was looking for, but it projector was not great"
    },
    {
        'name': "Timothy Smith",
        'image':Image1,
        'status': 'Appealed',
        'when': "1 hour ago",
        'review': "Very clean office hot desk, and reasonable for the size and available"
    }
]


const Feedback = () => {

    return (
        <div className="saas-box col-span-12 border-stroke bg-white px-5 pt-7 pb-5 sm:px-7 xl:col-span-5">
            <h3 className={"section__title"}>Feedback</h3>
            <p className={"section__strap"}>Latest feedback from your hosted users.</p>

            <div className="py-6">
                <div className="flex flex-col gap-7">
                    {demoData.map((item, index) => (
                        <div className="feedback__user">
                            <div
                                className="feedback__user__image_container">
                                <img src={item?.image} alt="User"/>
                            </div>
                            <div className={'w-full'}>
                                <p className="text-black dark:text-white flex flex-row items-center">
                                    <span className="font-medium flex-1">{item?.name}</span>
                                    <span className={`badge-${item?.status}`}>{item?.status}</span>
                                </p>
                                <span
                                    className="text-sm">{item?.when}</span>

                                <p
                                    className="mt-2.5 text-black dark:text-white ">
                                    {item?.review}
                                </p>
                            </div>

                            {index == 0 && (
                                <span
                                    className="absolute left-8 -z-1 block h-[300%] w-[1px] border-l border-dashed border-stroke dark:border-strokedark"></span>
                            )}
                        </div>
                    ))}


                </div>
            </div>
        </div>
    )


}

export {Feedback}