import {useUpdateSessionMutation} from "../../app/api/spacesApi";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {toast} from "react-toastify";
import {Form} from "react-router-dom";
import {StepFooter} from "./NewSpace";
import {Radio} from "../Forms/CheckboxFields";
import {InputFieldGroup} from "../Forms/InputField";

const cancelationSchema = yup
    .object({
        type: yup.string().required("Cancellation type must be selected"),
    })
    .required()

const CancellationComponent = ({setActiveStep, markStepAsCompleted, session, defaultData}) => {
    const [updateSession, {isLoading}] = useUpdateSessionMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: defaultData,
        resolver: yupResolver(cancelationSchema),
    })

    const submit = async (data) => {

        if (!isLoading) {
            const response = await updateSession({
                data: {
                    "session_hash": session,
                    "step": 6,
                    "data": {"cancellation": data}
                }
            })

            if (response.error) {
                toast.error(response.error.message)
                return false;
            }

            if (response.data?.success) {
                toast.success("Space Cancellation type updated successfully")
                markStepAsCompleted(6)
                setActiveStep(null)
            }

        }
    }

    return (
        <>
            <div className={'new-space__header'}>
                <h1 className={'title'}>Choose a cancellation and refund policy</h1>
                <p className={"strapline"}>The more flexible your cancellation the more attractive your space.</p>
            </div>
            <div className={'new-space__location'}>
                <Form onSubmit={handleSubmit(submit)} id="cancellationForm" className="space__cancellation_form">
                    <fieldset className="input__fieldset new-space__subsection">
                        <div className={"new-space__subheader"}>
                            <p className={'new-space__subsection_strap'}><strong>Cohesii grace period: </strong>
                                All space bookings are subject to Cohesii's cancellation policy across all services,
                                which allows a full refund on cancellations within 24 hours of a confirmed booking if a booking is more than three days ahead, or 12 hours of confirmed if less than 3 days. But no cancellation is allowed 48 hours prior to the booking.

                                Commission is 50% liable for all cancellations.
                            </p>

                            <InputFieldGroup className={'!flex-col'}>
                                <Radio id={`type_1`}
                                       name={'type'}
                                       label={"Very Flexible - Best for pay as you go"}
                                       showLabel={true}
                                       value={"best"}
                                       register={register}
                                       errors={"type"}
                                />
                                <div>

                                    <p className={'text-gray-500 mt-2'}>
                                        Can be cancelled up to 6 hours before booking
                                        if booked more than 3 days in advance, or 12 hours if less than 3 days.
                                    </p>

                                    <p className={'text-gray-500 mt-2'}>
                                       Cancellations submitted up to 24 hours before will receive a full refund, or a 50% refund including all fees for 12 hours.
                                    </p>
                                </div>

                            </InputFieldGroup>

                            <InputFieldGroup className={'!flex-col !mt-8'}>
                                <Radio id={`type_2`}
                                       name={'type'}
                                       label={"Flexible - Best for part time"}
                                       showLabel={true}
                                       value={"flexible"}
                                       register={register}
                                       errors={"type"}
                                />
                                <div>

                                    <p className={'text-gray-500 mt-2'}>
                                        Can be cancelled up to 7 days before booking for a full refund include fees.
                                    </p>

                                    <p className={'text-gray-500 mt-2'}>
                                        Cancellations submitted between 7 days and 24 hours before will receive a 50% refund excluding all fees. Cancellations after this period are not refundable.
                                    </p>
                                </div>

                            </InputFieldGroup>

                            <InputFieldGroup className={'!flex-col !mt-8'}>
                                <Radio id={`type_3`}
                                       name={'type'}
                                       label={"Standard 30 days - Best for short term and long term"}
                                       showLabel={true}
                                       value={"standard"}
                                       register={register}
                                       errors={"type"}
                                />
                                <div>

                                    <p className={'text-gray-500 mt-2'}>
                                        Can be cancelled up to 30 days before booking for a full refund include fees.
                                    </p>

                                    <p className={'text-gray-500 mt-2'}>
                                        Cancellations submitted between 30 days and 3 days before will receive a 50% refund excluding all fees. Cancellations after this period are not refundable.
                                    </p>
                                </div>

                            </InputFieldGroup>

                        </div>
                    </fieldset>


                    <StepFooter
                        setActiveStep={setActiveStep}
                    />
                </Form>
            </div>
        </>
)

}

export default CancellationComponent