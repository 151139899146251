export const getPrevious12Months = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentMonth = new Date().getMonth();
    const result = [];
    for (let i = 12; i > 0; i--) {
        result.push(months[(currentMonth + 12 - i) % 12]);
    }
    return result;
}

export const getWeeksFromLastMonthToCurrent = () => {
    const today = new Date();
    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const weeks = [];
    let startDate = new Date(lastMonth);

    // Function to format the date nicely as dd/mm
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth is zero-based
        return `${day}/${month}`;
    };

    while (startDate <= today) {
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        if (endDate > today) {
            endDate.setDate(today.getDate());
        }

        weeks.push(
            `Week ${weeks.length + 1}: ${formatDate(startDate)} - ${formatDate(endDate)}`
        );

        startDate.setDate(startDate.getDate() + 7);
    }

    return weeks;
}
export const getDaysInCurrentMonth = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const days = [];
    const currentMonthStart = new Date(today.getFullYear(), currentMonth, 1);
    const nextMonthStart = new Date(today.getFullYear(), currentMonth + 1, 1);

    for (let date = currentMonthStart; date < nextMonthStart; date.setDate(date.getDate() + 1)) {
        days.push(date.toDateString());
    }

    return days;
}