import './Styles.css'
import CalculatorForm from "./Form";
import {useState} from "react";
import CalculatorResults from "./Results";
import {useCalculateMutation} from "../../../app/api/aiApi";
import Loading from "../../../Components/Loading";

const Calculator = () => {

    const [price, setPrice] = useState(0.00);
    const [options, setOptions] = useState({
        "size": 350,
        "people": 1,
        "margin": 15,
        "min_hours": 1,
    });

    const [calculate, {isLoading, isSuccess}] = useCalculateMutation()

    const callback = async (data = {}) => {

        setOptions(data)

        const response = await calculate({calculate: data})
        if (response.data.price) {
          setPrice(response.data.price)
        }
    }

    return (
        <div className={"mt-8 rounded-sm border border-stroke bg-white px-5 pt-6 pb-6 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7"}>
            <div className={'calculator-space__header'}>
                <h1 className={'title'}>Recommended Price Calculator</h1>
                <p className={"strapline"}>Use the below AI model calculator to get a recommend advertisement price based on 100,000 UK spaces.</p>
            </div>

              <div className={'calculator-space__row'}>
                  <div className={'flex flex-col md:flex-row gap-[50px]'}>
                      <div className={'flex flex-col flex-1'}>
                          <CalculatorForm  callback={callback}/>
                      </div>

                      <div className={'flex flex-col  flex-1'}>
                          <Loading isLoading={isLoading} />
                          {!isLoading &&
                            <CalculatorResults data={options} price={price} isLoading={isLoading} />
                          }
                      </div>
                  </div>
              </div>
        </div>
    )

}

export default Calculator