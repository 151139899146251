import {Helmet} from "react-helmet-async";
import SaasHeader from "../../../Components/SaasHeader";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LicenseeRoleNavLink, OwnerRoleNavLink} from "../../../Components/RoleNavLinks";
import './styles.css'
const Settings = () => {

    return (
        <>
        <Helmet>
            <title>Cohesii | Settings | Billing Payouts</title>
        </Helmet>

        <SaasHeader title={"Account Settings"} add={false} />


            <div className={'grid grid-cols-1 md:grid-cols-3 gap-6'}>
                <div className={'flex flex-col '}>
                    <h3 className={"section__title !flex-none"}>General details</h3>
                    <p className={"section__strap"}>Manage your personal account settings.</p>

                    <NavLink to={'/account/details'} className={'settings-link green'}>
                        <FontAwesomeIcon icon="fa-solid fa-user" />
                        <div className={'flex flex-col'}>
                            <p className={"section__title"}>Account details</p>
                            <p className={"section__strap"}>Update your personal information.</p>
                        </div>
                    </NavLink>
                </div>

                <div className={'flex flex-col'}>
                    <h3 className={"section__title !flex-0"}>Billing details</h3>
                    <p className={"section__strap"}>Manage your billing and payment settings..</p>
                    <div className={'flex flex-col gap-4'}>
                        <OwnerRoleNavLink to={'/account/payout/details'} className={'settings-link red'}>
                            <FontAwesomeIcon icon="fa-solid fa-money-bill-transfer" />
                            <div className={'flex flex-col'}>
                                <p className={"section__title"}>Payout details</p>
                                <p className={"section__strap"}>Manage your billing and payment settings.</p>
                            </div>
                        </OwnerRoleNavLink>
                        <LicenseeRoleNavLink  to={'/account/billing/details'} className={'settings-link red'}>
                            <FontAwesomeIcon icon="fa-brands fa-cc-visa" />
                            <div className={'flex flex-col '}>
                                <p className={"section__title"}>Payment details</p>
                                <p className={"section__strap"}>Manage how you pay for spaces.</p>
                            </div>
                        </LicenseeRoleNavLink>
                    </div>
                </div>



            </div>

        </>
    )
}

export default Settings