import {loadStripe} from "@stripe/stripe-js";
import {useSelector} from "react-redux";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";

import {toast} from "react-toastify";
import {useState} from "react";
import {useAddBankCardMutation} from "../../../../app/api/userApi";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AddCardForm = ({details, callback = ()=> {}}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setIsProcessing] = useState(false);

    const [addBankCard, {isLoading, isError}] = useAddBankCardMutation()

    const userEmail = useSelector((state) => state.auth.user?.email);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return; // Stripe.js hasn't yet loaded
        }

        setIsProcessing(true);

        // Get card details from CardElement
        const cardElement = elements.getElement(CardElement);

        // Create a payment method or token from the card details
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: details?.name,
                email: userEmail,
                phone: details?.phone,
                address: {
                    country: details?.country,
                    line1: details?.line1,
                    postal_code: details?.postal_code,
                    city: details?.city,
                },
            },
        });

        if (error) {
            toast.error(error.message);
            setIsProcessing(false);
        } else {
            try {
                // Send the payment method to your backend for further processing
                const response = await addBankCard({token: paymentMethod.id})
                if (response.data.success) {
                    toast.success("Card added successfully");
                    callback()
                }
            } catch (error) {
                toast.error('There was an error processing your payment method');
            } finally {
                setIsProcessing(false);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h3 className={"section__title"}>Add a new bank account</h3>
            <p className={"section__strap"}>Add a bank account that your payments will be paid to.</p>

            <CardElement options={ { hidePostalCode: true } }   />

            <button className={
                'btn__auth_primary small'
            } type="submit" disabled={!stripe || isProcessing}>
                {isProcessing ? 'Processing...' : 'Add Card'}
            </button>
        </form>
    );
};

const AddCardComponent = (props) => {

    return (
        <div>
            {props?.stipeCustomerId && (
                <Elements stripe={stripePromise}>
                <AddCardForm {...props}  />
                </Elements>
            )}
        </div>
    );
};

export default AddCardComponent;