import { ReactNode, useState } from 'react';
import {useSelector} from "react-redux";

const SidebarLinkGroup = ({
                              children,
                              requiredRole,
                              activeCondition,
                          }) => {
    const [open, setOpen] = useState(activeCondition);

    const userRoles = useSelector((state) => state.auth.user?.roles);

    const handleClick = () => {
        setOpen(!open);
    };

    return <>{userRoles?.includes(requiredRole) || requiredRole === "*"  && <li>{children(handleClick, open)}</li> }</>
};

export default SidebarLinkGroup;