const Prompts = ({setQueryAndSearch}) => {

    const prompts = [
        "I need an office space in london with parking for 3 people",
        "As a photographer i need a photo studio fully equipped for 10 people",
        "Me and my partner are getting married and need a venue with a bar",
        "My team need a meeting room that is a unique space for 20 people and kitchen facilities for a day"

    ]
    const getMap = () => {
        return (prompts.map((query, index)=> {
            return (
                <button onClick={(e) => {
                    setQueryAndSearch(e, query)
                }} key={'idea'+index} className={"idea-prompt"}>

                    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" >
                        <line x1="50.4" y1="24.38" x2="58.3" y2="23.14"/>
                        <line x1="47.93" y1="17.11" x2="52.87" y2="14.2"/>
                        <line x1="42.89" y1="11.73" x2="46.21" y2="4.51"/>
                        <line x1="33.45" y1="10.69" x2="33.41" y2="4.96"/>
                        <line x1="24.29" y1="12.09" x2="21.62" y2="4.51"/>
                        <line x1="17.99" y1="17.03" x2="12.96" y2="14.29"/>
                        <line x1="15.78" y1="23.97" x2="8.03" y2="22.66"/>
                        <path d="M26.22,45.47c0-5.16-3.19-9.49-4.91-12.69A12.24,12.24,0,0,1,19.85,27c0-6.79,6.21-12.3,13-12.3"/>
                        <path d="M39.48,45.47c0-5.16,3.19-9.49,4.91-12.69A12.24,12.24,0,0,0,45.85,27c0-6.79-6.21-12.3-13-12.3"/>
                        <rect x="23.63" y="45.19" width="18.93" height="4.25" rx="2.12"/>
                        <rect x="24.79" y="49.43" width="16.61" height="4.25" rx="2.12"/>
                        <path d="M36.32,53.68v.84a3.23,3.23,0,1,1-6.44,0v-.84"/>
                        <path d="M24.57,26.25a7.5,7.5,0,0,1,7.88-7.11"/>
                    </svg>

                    {query}
                </button>
            )
        }))
    }

    return (

            <div className={"example-prompts-column-container"}>
                <div className={"example-prompts-column"}>
                    <div className={"search-title"}>
                        <svg className={"trending"} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                            <polyline points="8.07 45.3 24.8 29.23 35.31 39.49 55.43 19.45"/>
                            <polyline points="44.99 19.12 55.43 19.4 55.14 29.84"/>
                        </svg>
                        <div className={"text"}>
                            <h4>Trending searches</h4>
                            <p>Based on the top 4 most used query's of all time.</p>
                        </div>
                    </div>

                    {getMap()}
                </div>
            </div>

    )
}

export default Prompts