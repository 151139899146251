import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useLocation} from "react-router-dom";
import Logo from '../../assets/cohesii-white.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './styles.css'
import {useSelector} from "react-redux";
import ClickOutside from "../ClickOutside";
import SidebarLinkGroup from "./SidebarLinkGroup";
import {LicenseeRoleNavLink, OwnerRoleNavLink} from "../RoleNavLinks";

const Sidebar = ({sidebarOpen = false, setSidebarOpen}) => {

    const location = useLocation();
    const { pathname } = location;

    const sidebar = useRef(null);
    const trigger = useRef(null);

    const userRoles = useSelector((state) => state.auth.user?.roles);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    );

    return (
        <ClickOutside onClick={() => setSidebarOpen(false)} className="sidebar">
            <aside
                ref={sidebar}
                className={`absolute left-0 top-0 z-9999 flex h-screen w-[18rem] flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
                    sidebarOpen ? 'translate-x-0' : '-translate-x-full'
                }`}
            >

                <div className="flex items-center justify-between gap-2 px-6 py-5 lg:py-6.5">
                    <NavLink to="/">
                        <img src={Logo} alt="Cohesii orange gradient" title={"Cohesii Portal"}
                             className={"w-full max-w-[200px] object-cover"}/>
                    </NavLink>

                    <button
                        ref={trigger}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        aria-controls="sidebar"
                        aria-expanded={sidebarOpen}
                        className="block lg:hidden"
                    >
                        <FontAwesomeIcon icon={'fas fa-xmark'} className={"size-6 text-white"}/>
                    </button>



                </div>
                <div className="no-scrollbar flex-1 flex flex-col overflow-y-auto duration-300 ease-linear">
                    <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6 flex-1">

                        <div>
                            <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">
                                MENU
                            </h3>

                            <ul className="mb-6 flex flex-col gap-1.5">
                                <li>
                                    <NavLink
                                        to="/dashboard"
                                        className={`group sidebar__link ${
                                            pathname.includes('dashboard') && 'active'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-gauge"/>
                                        Dashboard
                                    </NavLink>
                                </li>


                                <SidebarLinkGroup
                                    requiredRole={'*'}
                                    activeCondition={
                                        pathname === '/spaces' || pathname.includes('spaces')
                                    }
                                >
                                    {(handleClick, open) => {
                                        return (
                                            <React.Fragment>
                                                <NavLink
                                                    to="#"
                                                    className={`group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                                        (pathname === '/spaces' ||
                                                            pathname.includes('spaces')) &&
                                                        'bg-graydark dark:bg-meta-4'
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        sidebarExpanded
                                                            ? handleClick()
                                                            : setSidebarExpanded(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="fa-solid fa-building"/>
                                                    Spaces
                                                    <svg
                                                        className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                                                            open && 'rotate-180'
                                                        }`}
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            clipRule="evenodd"
                                                            d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                                                            fill=""
                                                        />
                                                    </svg>
                                                </NavLink>

                                                <div
                                                    className={`translate transform overflow-hidden ${
                                                        !open && 'hidden'
                                                    }`}
                                                >
                                                    <ul className="mt-4 mb-5.5 flex flex-col gap-2.5">

                                                        <OwnerRoleNavLink
                                                            to="/spaces/mine"
                                                            className={({isActive}) =>
                                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                                (isActive && '!text-white')
                                                            }
                                                        >
                                                            My Spaces
                                                        </OwnerRoleNavLink>

                                                        <OwnerRoleNavLink
                                                            to="/spaces/calculator"
                                                            className={({isActive}) =>
                                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                                (isActive && '!text-white')
                                                            }
                                                        >
                                                            Price Calculator
                                                        </OwnerRoleNavLink>


                                                        <LicenseeRoleNavLink
                                                            to="/spaces/match"
                                                            className={({isActive}) =>
                                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                                (isActive && '!text-white')
                                                            }
                                                        >
                                                            Match a space
                                                        </LicenseeRoleNavLink>

                                                    </ul>
                                                </div>

                                            </React.Fragment>
                                        );
                                    }}
                                </SidebarLinkGroup>

                                <li>
                                    <NavLink
                                        to="/bookings"
                                        className={`group sidebar__link ${
                                            pathname.includes('bookings') && 'active'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-calendar-days"/>
                                        Bookings
                                    </NavLink>
                                </li>

                                <li>
                                    <OwnerRoleNavLink
                                        to="/feedback"
                                        className={`group sidebar__link ${
                                            pathname.includes('feedback') && 'active'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-face-smile"/>
                                        Feedback
                                    </OwnerRoleNavLink>

                                </li>

                                <li>
                                    <OwnerRoleNavLink
                                        to="/payouts"
                                        className={`group sidebar__link ${
                                            pathname.includes('payouts') && 'active'
                                        }`}
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-money-bill-transfer" />
                                        Bank Payouts
                                    </OwnerRoleNavLink>

                                </li>

                            </ul>

                        </div>
                    </nav>
                    <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
                        <ul className="mb-6 flex flex-col gap-1.5">
                            <li>
                                <NavLink
                                    to="/account/overview"
                                    className={`group sidebar__link ${
                                        pathname.includes('account') && 'active'
                                    }`}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-gear"/>
                                    Settings
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/logout"
                                    className={`group sidebar__link ${
                                        pathname.includes('logout') && 'active'
                                    }`}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />
                                    Logout
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </ClickOutside>
    )


}

export default Sidebar